import React, { useEffect } from "react";
import StickyMenu from "../../lib/StickyMenu";
import BackToTop from "../BackToTop";
import FooterHomeThree from "../HomeThree/FooterHomeThree";
import ProjectHomeThree from "../HomeThree/ProjectHomeThree";
import Drawer from "../Mobile/Drawer";
import HeaderService from "../Service/HeaderService";
import useToggle from "../../Hooks/useToggle";
import HeroTrust from "./HeroTrust";
import TrustPage from "./TrustPage";
import SponserHomeTwo from "../HomeTwo/SponserHomeTwo";

function Trust() {
	useEffect(() => {
		StickyMenu();
	});
	const [drawer, drawerAction] = useToggle(false);

	return (
		<>
			<Drawer drawer={drawer} action={drawerAction.toggle} />
			<HeaderService action={drawerAction.toggle} />
			<HeroTrust />
			<br /> <br /> <br />
			<TrustPage />
			<br /> <br />
			<SponserHomeTwo />
			<ProjectHomeThree />
			<FooterHomeThree />
			<BackToTop />
		</>
	);
}

export default Trust;
