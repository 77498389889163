import React, { Component } from "react";
import Axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";

export class BlogHomeOne extends Component {
	state = {
		post: [],
	};

	mediumURL =
		"https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@suavereconn";

	componentDidMount() {
		Axios.get(this.mediumURL)
			.then((data) => {
				const res = data.data.items.filter(
					(item) => item.categories.length > 0
				);
				this.setState({
					post: res,
				});
			})
			.catch((e) => {
				this.setState({ error: e.toJSON() });
				console.log(e);
			});
	}

	render() {
		const renderPostItem = (index) => {
			const item = this.state.post[index];
			if (!item) return null;

			const imgurl = item.description;
			const match =
				typeof imgurl === "string" && imgurl.match(/<img[^>]+src="([^">]+)"/);
			const imageSrc = match ? match[1] : null;

			return (
				<div className="col-lg-4 col-md-6" key={item.id}>
					<div
						className="appie-blog-item mt-30 wow animated fadeInUp"
						data-wow-duration="3000ms"
						data-wow-delay="200ms"
					>
						<div className="thumb">
							<img src={imageSrc || item.thumbnail} alt="" width={"20px"} />
						</div>
						<div className="content">
							<div className="blog-meta">
								<ul>
									<li>{moment(item.pubDate).format("MMMM Do, YYYY")}</li>
									<li>
										<a href="#">Popular Post</a>
									</li>
								</ul>
							</div>
							<h3 className="title">
								<Link to={`/news/${item.title}`}>{item.title}</Link>
							</h3>
							<Link to={`/news/${item.title}`}>
								Learn More <i className="fal fa-arrow-right" />
							</Link>
						</div>
					</div>
				</div>
			);
		};

		return (
			<>
				<section className="appie-blog-area pt-90 pb-95">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="appie-section-title text-center">
									<h3 className="appie-title">Popular posts</h3>
									<p>Different layouts and styles for team sections.</p>
								</div>
							</div>
						</div>
						<div className="row">
							{renderPostItem(0)}
							{renderPostItem(1)}
							{renderPostItem(2)}
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default BlogHomeOne;
