import React, { Fragment } from "react";
import Faq from "react-faq-component";
import data from "./data";


function DownloadHomeThree({ className }) {
    return (
        <>
            <section className={`appie-download-3-area pt-100 ${className || ''}`} id="download">
                <div className="container">
                <Fragment>
      <Faq
        data={data}
        styles={{
          bgColor: "white",
          titleTextColor: "#0e1133",
          rowTitleColor: "#000000",
          rowTitleTextSize: "large",
          rowContentColor: "#726f84",
          rowContentTextSize: "16px",
          rowContentPaddingTop: "10px",
          rowContentPaddingBottom: "10px",
          rowContentPaddingLeft: "50px",
          rowContentPaddingRight: "150px",
          arrowColor: "#0e1133"
        }}
        config={{
          animate: true
        }}
      />
    </Fragment>
                </div>
            </section>
        </>
    );
}

export default DownloadHomeThree;
