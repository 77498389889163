import React from 'react';
import {useLocation} from 'react-router-dom';

function HeroAbout() { 
    const location = useLocation();
    const titleselect = location.pathname === '/about-us' ? 'About Us' : location.pathname === '/terms' ? `Terms & Conditions` : location.pathname === '/privacy' ? 'Privacy Policy' : location.pathname === '/legal' && 'Legal' ;
    return (
            <div className="appie-about-top-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="appie-about-top-title">
                                <h2 className="title">{titleselect}</h2>
                                <p>Home{location.pathname}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default HeroAbout;
