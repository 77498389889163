import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Drawer from "../Mobile/Drawer";
import HeaderAbout from "../AboutUs/HeaderAbout";
import useToggle from "../../Hooks/useToggle";
import ProjectHomeThree from "../HomeThree/ProjectHomeThree";
import FooterHomeThree from "../HomeThree/FooterHomeThree";
import BackToTop from "../BackToTop";
import moment from "moment";
import Loader from "../Helper/Loader";
function Scaminfo() {
	const location = useLocation();
	const [error, seterror] = useState(null);
	const [loading, setLoading] = useState(true);
	const [analysis, setanalysis] = useState(null);
	const [drawer, drawerAction] = useToggle(false);
	const [firstName, setFirstName] = useState(null);
	const [lastName, setLastName] = useState(null);
	const [email, setEmail] = useState(null);
	const [phone, setPhone] = useState(null);
	const [subject, setSubject] = useState(null);
	const [message, setMessage] = useState(null);
	const [check, setCheck] = useState(false);
	const [submit, setSubmit] = useState(false);
	const [errorMsg, setErrorMsg] = useState(false);
	const { url } = location.state;
	const handleInputChange = (e) => {
		const { name, value, type, checked } = e.target;
		if (name === "f-name") {
			setFirstName(value);
		}
		if (name === "l-name") {
			setLastName(value);
		}
		if (name === "email") {
			setEmail(value);
		}
		if (name === "phone") {
			setPhone(value);
		}
		if (name === "subject") {
			setSubject(value);
		}
		if (name === "message") {
			setMessage(value);
		}
		if (type === "checkbox") {
			setCheck(checked);
		}
	};

	const onsamasubSubmit = (e) => {
		e.preventDefault();
		const msg = `FirstName: ${firstName} , LastName: ${lastName} , Email: ${email} , Number: ${phone} , Subject: ${subject} , Description: ${message}`;
		if (
			firstName != null &&
			lastName != null &&
			subject != null &&
			email != null &&
			message != null &&
			check
		) {
			axios
				.get(
					`https://api.telegram.org/bot${process.env.REACT_APP_BOT_SECRET}/sendMessage?chat_id=${process.env.REACT_APP_CHAT_SECRET}&text=${msg}`
				)

				.then((data) => {
					setSubmit(true);
					window.scrollTo({
						top: 200,
						behavior: "smooth",
					});
					setTimeout(() => {
						setSubmit(false);
						window.location.reload();
					}, 3500);
				})
				.catch((e) => {});
		} else {
			setErrorMsg(true);
			setSubmit(false);
		}
	};
	useEffect(() => {
		const options = {
			method: "GET",
			url: "https://ip2whois-whois-information-lookup.p.rapidapi.com/",
			params: {
				key: "5A0DD7F4B301AC41DBF47DBD33596413",
				domain: url,
				format: "json",
			},
			headers: {
				"X-RapidAPI-Key": "925fa1ead1mshd30c99d956455acp1fbc2ajsn685a41b6d8b7",
				"X-RapidAPI-Host": "ip2whois-whois-information-lookup.p.rapidapi.com",
			},
		};

		axios
			.request(options)
			.then(function (response) {
				setanalysis(response.data);
				setLoading(false);
			})
			.catch(function (error) {
				seterror(error);
			});
	}, []);

	return (
		<>
			{loading ? (
				<div className={`appie-loader ${loading ? "active" : ""}`}>
					<Loader />
				</div>
			) : (
				<>
					<Drawer drawer={drawer} action={drawerAction.toggle} />
					<HeaderAbout drawer={drawer} action={drawerAction.toggle} />
					<section className="appie-service-details-area pt-100 pb-100">
						<div className="container">
							<div className="web_analysis">
								<h1 className="head">Scam site info</h1>
								<div className="whoisitems">
									<h6>
										<b>domain:{analysis && analysis.domain}</b>
									</h6>
									<h6>
										<b>
											created:{" "}
											{analysis &&
												moment(analysis.create_date).format(
													"dddd, MMMM Do YYYY"
												)}
										</b>
									</h6>
									<h6>
										<b>
											updated:{" "}
											{analysis &&
												moment(analysis.update_date).format(
													"dddd, MMMM Do YYYY"
												)}
										</b>
									</h6>
									<h6>
										<b>
											expires:{" "}
											{analysis &&
												moment(analysis.expire_date).format(
													"dddd, MMMM Do YYYY"
												)}
										</b>
									</h6>
									<h6>
										<b>country of registration: </b>
										<b>{analysis && analysis.registrant.region}</b>
										{", "}
										<b>{analysis && analysis.registrant.country}</b>
									</h6>
									<h6>
										<b>NameServers:</b>
										{analysis &&
											analysis.nameservers.map((items) => (
												<h6>
													<b>{items}</b>
												</h6>
											))}
									</h6>
								</div>
								<div>
									<h3 className="sub_head">is {url} a scam broker ?</h3>
									<p>
										You have a chance to get your money back if you dealt with
										{url} and think they defrauded you. It has been shown that
										many unlicensed brokers defraud traders. Our goal at Broker
										Complaint Alert is to give you a thorough {url} evaluation
										so you may take legal action and get your money back. You
										have already taken the first step toward getting your money
										back by completing the form.
									</p>
									<h3 className="sub_head">is {url} a legit broker ?</h3>
									<p>
										Broker {url} has been referred to as a con artist. If you
										are trading with {url}, get in touch with them right away
										and try to get your money out. We do not advise doing so. If{" "}
										{url} has engaged in any of the following actions, you have
										likely fallen victim to a {url} scam broker.
									</p>
								</div>
								<div className="col-md-8">
									<div className="contact-form info-report">
										<h4>Submit a Report</h4>
										<br />
										<form
											onSubmit={onsamasubSubmit}
											method="GET"
											className="row"
										>
											<div className="col-md-6">
												<input
													type="text"
													name="f-name"
													placeholder="Name"
													onChange={(e) => handleInputChange(e)}
												/>
											</div>
											<div className="col-md-6">
												<input
													type="email"
													name="email"
													placeholder="Email Address"
													onChange={(e) => handleInputChange(e)}
												/>
											</div>
											<div className="col-md-12">
												<input
													type="text"
													name="subject"
													placeholder="Report Title"
													onChange={(e) => handleInputChange(e)}
												/>
											</div>
											<div className="col-md-12">
												<textarea
													name="message"
													placeholder={`Tell us your experience with ${url}`}
													onChange={(e) => handleInputChange(e)}
													checked={check}
												></textarea>
											</div>
											{errorMsg && (
												<h5 style={{ color: "red", margin: "15px" }}>
													Please Fill All information Before Submitting
												</h5>
											)}
											<div className="col-md-6">
												<input
													type="submit"
													name="submit"
													value="Submit Report"
												/>
											</div>
										</form>
									</div>
								</div>
								<div className="web_analysis">
									<h1>How to Spot a Scam Website</h1>
									<p>
										1. Domain Creation: Businesses that claim to have been
										established for several years must have a domain name
										registration that corresponds to that year unless they claim
										to have changed it. However, even in cases where names and
										other information have been changed as a result of internal
										disputes, such businesses are still recognized as
										legitimate. Note: For instance, if a firm claims to have
										been established in 2020 but hasn't made any changes to the
										domain, a registration alert will be raised. Also, keep in
										mind that only newly registered domains have their updated
										and created dates match; established domains have created
										and updated dates separated by at least two months.
									</p>
									<p>
										2. Country and Region: Legitimate businesses allow you to
										see their regions; any domain that only provides you with
										the country of registration is a red flag. The region
										provided must correspond to the location Head Office
										provided on the website. For example, country of
										registration: FL US. The above example contains its region
										which must match the address and zip from the website.
									</p>
									<p>
										3. DNS Details: Copy just one nameserver, for instance,
										ns1.dns-parking.com, and search on Google. It should show
										companies that provide the nameserver and must also be
										well-known companies like godaddy, namecheap, hostinger, or
										even their own company's nameservers, for example
										google.com, Nameservers:ns1.google.com. Organizations choose
										the best hosting, not some low-cost hosting or domain
										suppliers.
									</p>
									{analysis && (
										<div className="rawwho">
											<h5>Raw Whois:</h5>
											<h6>Domain: {analysis && analysis.domain}</h6>
											<h6>Domain id: {analysis && analysis.domain_id}</h6>
											<h6>Creation Date: {analysis && analysis.create_date}</h6>
											<h6>Updated Date: {analysis && analysis.update_date}</h6>
											<h6>
												Registry Expiry Date: {analysis && analysis.expire_date}
											</h6>
											<h6>Status: {analysis && analysis.status}</h6>
											<h6>Whois Server: {analysis && analysis.whois_server}</h6>
											<h6>Admin City: {analysis && analysis.admin.city}</h6>
											<h6>
												Admin country: {analysis && analysis.admin.country}
											</h6>
											<h6>Admin Email: {analysis && analysis.admin.email}</h6>
											<h6>Admin Fax: {analysis && analysis.admin.fax}</h6>
											<h6>Admin Name: {analysis && analysis.admin.name}</h6>
											<h6>
												Admin Organisation:{" "}
												{analysis && analysis.admin.organization}
											</h6>
											<h6>Admin Phone: {analysis && analysis.admin.phone}</h6>
											<h6>Admin Region: {analysis && analysis.admin.region}</h6>
											<h6>
												Admin Street Address:{" "}
												{analysis && analysis.admin.street_address}
											</h6>
											<h6>Admin Zip: {analysis && analysis.admin.zip_code}</h6>
											<h6>
												Registrant City: {analysis && analysis.registrant.city}
											</h6>
											<h6>
												Registrant country:{" "}
												{analysis && analysis.registrant.country}
											</h6>
											<h6>
												Registrant Email:{" "}
												{analysis && analysis.registrant.email}
											</h6>
											<h6>
												Registrant Fax: {analysis && analysis.registrant.fax}
											</h6>
											<h6>
												Registrant Name: {analysis && analysis.registrant.name}
											</h6>
											<h6>
												Registrant Organisation:{" "}
												{analysis && analysis.registrant.organization}
											</h6>
											<h6>
												Registrant Phone:{" "}
												{analysis && analysis.registrant.phone}
											</h6>
											<h6>
												Registrant Region:{" "}
												{analysis && analysis.registrant.region}
											</h6>
											<h6>
												Registrant Street Address:{" "}
												{analysis && analysis.registrant.street_address}
											</h6>
											<h6>
												Registrant Zip:{" "}
												{analysis && analysis.registrant.zip_code}
											</h6>
											<h6>
												Registrar iana id:{" "}
												{analysis && analysis.registrar.iana_id}
											</h6>
											<h6>
												Registrar Name: {analysis && analysis.registrar.name}
											</h6>
											<h6>
												Registrar Url: {analysis && analysis.registrar.url}
											</h6>
											<h6>Tech City: {analysis && analysis.tech.city}</h6>
											<h6>Tech country: {analysis && analysis.tech.country}</h6>
											<h6>Tech Email: {analysis && analysis.tech.email}</h6>
											<h6>Tech Fax: {analysis && analysis.tech.fax}</h6>
											<h6>Tech Name: {analysis && analysis.tech.name}</h6>
											<h6>
												Tech Organisation:{" "}
												{analysis && analysis.tech.organization}
											</h6>
											<h6>Tech Phone: {analysis && analysis.tech.phone}</h6>
											<h6>Tech Region: {analysis && analysis.tech.region}</h6>
											<h6>
												Tech Street Address:{" "}
												{analysis && analysis.tech.street_address}
											</h6>
											<h6>Tech Zip: {analysis && analysis.tech.zip_code}</h6>
											{analysis &&
												analysis.nameservers.map((items) => (
													<h6>NameServers: {items}</h6>
												))}
										</div>
									)}
								</div>
							</div>
						</div>
					</section>
					<ProjectHomeThree />
					<FooterHomeThree className="appie-footer-about-area" />
					<BackToTop />
				</>
			)}
		</>
	);
}

export default Scaminfo;
