import React from 'react';
import {Link,useLocation} from 'react-router-dom';
function DetailsPrivacy() {
    const location = useLocation();
    return (
        <>
            <section className="appie-service-details-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="service-details-sidebar mr-50">
                              
                                <div className="service-download-widget">
                                    <Link to="/about-us">
                                        <i className="fal fa-file"></i>
                                        <span>About Us</span>
                                    </Link>
                                </div>
                                <div className="service-download-widget">
                                    <Link to="/terms">
                                        <i className="fal fa-file"></i>
                                        <span>{`Terms & Conditions`}</span>
                                    </Link>
                                </div>
                                <div className="service-download-widget">
                                    <Link to="/privacy">
                                        <i className="fal fa-file"></i>
                                        <span>Privacy Policy</span>
                                    </Link>
                                </div>
                                <div className="service-download-widget">
                                    <Link to="/legal">
                                        <i className="fal fa-file"></i>
                                        <span>Legal</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="service-details-content">
                                <div className="content">
                                    <h3 className="title">Introduction.</h3>
                                    <p>
                                    Your protection means quite a bit to us. It is recompense ltd.com's strategy to regard your protection in regards to any data we might gather from you across our site and different locales we own and work. We possibly request individual data when we really need it to offer a support to you. We gather it by fair and legitimate means, with your insight and assent. We likewise let you in on why we're gathering it and how it will be utilized. We just hold gathered data however long important to give you your mentioned administration. What information we store, we'll safeguard inside financially adequate means to forestall misfortune and robbery, as well as unapproved access, exposure, duplicating, use or change. We don't share any expressly recognizing data openly or with outsiders, aside from when expected to by regulation. Our site might connection to outer destinations that are not worked by us.
                                     </p>
                                    <h3 className="title">Overview.</h3>
                                    <p>
                                    This site utilizes Cookies to work on your experience while you explore through the site. Out of these Cookies, the Cookies that are arranged as vital are put away on your program as they are as fundamental for the working of essential functionalities of the site. We additionally utilize outsider Cookies that help us examine and comprehend how you utilize this site. These Cookies will be put away in your program just with your assent. You likewise have the choice to quit these Cookies. In any case, quitting a portion of these Cookies might meaningfully affect your perusing experience.
                                    </p>
            
                                    <h3 className="title">Cookie policy.</h3>
                                    <p>
                                    Like most sites, our sites use Cookies to gather data. Cookies are little information documents which are put on your PC or different gadgets, (for example, cell phones or tablets) as you peruse our sites. They are utilized to 'recollect' when your PC or gadget gets to our sites. Cookies are fundamental for the compelling activity of our site and to assist us with additional promoting our administrations.
                                    </p>
                                    <h3 className="title">Information collected.</h3>
                                    <p>
                                    A few Cookies gather data about perusing when you access our sites through a similar PC or gadget. This incorporates data about pages saw, the program you utilized and your excursion around a site. All information passed by Cookies is unknown and won't ever contain individual detail, for example, your name, address or phone number however may contain our client reference number that is special to you.
                                    </p> 

                                    <h3 className="title">How are Cookies managed.</h3>
                                    <p>
                                    The Cookies stored on your computer or other device when you access our websites are designed by:
                                    </p>
                                     <p>
                                     outsiders who partake with us in advertising programs andoutsiders who examine our site's traffic.
                                     </p>
                                    <h3 className="title">What are Cookies used for ?</h3>
                                    <p>
                                    The principal uses of Cookies are for practical purposes crucial for compelling activity of our site, especially according to site route and inclinations. They are additionally utilized for promoting and publicizing, especially web pennant notices and designated refreshes through computerized channels and virtual entertainment and furthermore for site investigation purposes, especially benefits by Google like Google Analytics and Google Tag Manager.
                                    </p>
                                    <p>
                                    By utilizing this site, you agree to the handling of information about you by Google in the way portrayed in Google's Privacy Policy (https://www.google.com/arrangements/security/) and for the reasons set out above. You can quit Google Analytics assuming you impair or decline the Cookie, handicap JavaScript, or utilize the quit administration given by Google (https://tools.google.com/dlpage/gaoptout).
                                    </p>
                                    <h3 className="title">Turning off and deleting Cookies.</h3>
                                    <p>
                                    Most internet browsers will give the choice to switch off or deny Cookies. How you do this relies upon the internet browser you are utilizing. Guidelines for denying Cookies can for the most part be tracked down in the program's 'Help' menu. Cookies can be erased utilizing your internet browser. Nonetheless, except if they are prohibited they will be re-applied the following time you visit a site. To handicap Cookies you really want to change your site program settings to dismiss Cookies. How you can do this will rely upon the program you use. Further subtleties on the most proficient method to handicap Cookies for the most well known work area programs are set over here (these may fluctuate relying upon working framework and program adaptation):
                                    </p>
                                    <span>For Microsoft Internet Explorer:</span>
                                   <p>
                                   Pick the menu "Devices" then "Web Options",Click on the "Security" tab Select "High level" then Pick the fitting settings
                                   </p>
                                   <span>For Google Chrome:</span>
                                   <p>
                                   Pick "Settings" and snap on "Cutting edge", Under "Protection and Security" then click "Content Settings" and Click "Cookies"</p>
                                   <span>For Safari:</span>
                                   <p>
                                   Pick Preferences , then Privacy and Click on "Block all Cookies"</p>
                                   <span>For Mozilla firefox:</span>
                                   <p>
                                   Click on the menu symbol then select "Choices" then Click on the symbol "Protection and Security", Track down the menu "Cookie" and select the important choices</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DetailsPrivacy;
