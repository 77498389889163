import React from "react";
import IconOne from "../../assets/images/icon/3.png";
import IconTwo from "../../assets/images/icon/iconcomplaint.png";
import IconThree from "../../assets/images/icon/iconsubmit.png";
import IconFour from "../../assets/images/icon/4.png";

function ServicesHomeOne({ className }) {
	return (
		<section
			className={`appie-service-area pt-90 pb-100 ${className}`}
			id="service"
		>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-6">
						<div className="appie-section-title text-center">
							<h3 className="appie-title">How We Operate?</h3>
							<p>Understand how we accomplish our goals. </p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-3 col-md-6">
						<div
							className="appie-single-service text-center mt-30 wow animated fadeInUp"
							data-wow-duration="2000ms"
							data-wow-delay="200ms"
						>
							<div className="icon">
								<img src={IconOne} alt="" />
								<span>1</span>
							</div>
							<h4 className="appie-title">Assessment</h4>
							<p>
								We evaluate the situation and determine the best course of
								action for your case.
							</p>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div
							className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
							data-wow-duration="2000ms"
							data-wow-delay="400ms"
						>
							<div className="icon">
								<img src={IconTwo} alt="" />
								<span>2</span>
							</div>
							<h4 className="appie-title">Investigation & Tracing</h4>
							<p>
								Our team conducts an in-depth investigation, tracing the source
								of the issue and gathering evidence.
							</p>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div
							className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
							data-wow-duration="2000ms"
							data-wow-delay="600ms"
						>
							<div className="icon">
								<img src={IconThree} alt="" />
								<span>3</span>
							</div>
							<h4 className="appie-title">Resolution</h4>
							<p>
								We work towards resolving the dispute, negotiating, or taking
								legal steps to settle the matter.
							</p>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div
							className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
							data-wow-duration="2000ms"
							data-wow-delay="800ms"
						>
							<div className="icon">
								<img src={IconFour} alt="" />
								<span>4</span>
							</div>
							<h4 className="appie-title">Recovery of Funds</h4>
							<p>
								Our ultimate goal is to recover your lost funds and ensure
								justice is served.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default ServicesHomeOne;
