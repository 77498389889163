import React from "react";

function ServicesHomeTwo({ className }) {
	return (
		<>
			<section
				className={`appie-services-2-area pb-100 ${className}`}
				id="service"
			>
				<div className="container">
					<div className="row align-items-end">
						<div className="col-lg-6 col-md-8">
							<div className="appie-section-title">
								<h3 className="appie-title"> Our Values</h3>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-6">
							<div
								className="appie-single-service-2 mt-30 wow animated fadeInUp"
								data-wow-duration="2000ms"
								data-wow-delay="200ms"
							>
								<div className="icon">
									<i className="fas fa-user-friends" />
								</div>
								<h4 className="title">Integrity</h4>
								<p>
									We operate with honesty and transparency at every step of the
									recovery process
								</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div
								className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
								data-wow-duration="2000ms"
								data-wow-delay="400ms"
							>
								<div className="icon">
									<i className="fas fa-handshake" />
								</div>
								<h4 className="title">Trust</h4>
								<p>
									We work hard to earn and maintain the trust of every client,
									offering secure and confidential services.
								</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div
								className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
								data-wow-duration="2000ms"
								data-wow-delay="600ms"
							>
								<div className="icon">
									<i className="fas fa-link" />
								</div>
								<h4 className="title">Excellence</h4>
								<p>
									We are relentless in our pursuit of excellence, using
									innovative techniques to deliver the best possible outcomes.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default ServicesHomeTwo;
