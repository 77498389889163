import React from "react";
import axios from "axios";
import moment from "moment";
class BlogSideBar extends React.Component {
	state = {
		post: {},
		categories: {},
	};

	mediumURL =
		"https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@suavereconn";

	componentDidMount() {
		axios
			.get(this.mediumURL)

			.then((data) => {
				// console.log(data.data)
				const res = data.data.items; //This is an array with the content. No feed, no info about author etc..
				this.setState({
					post: res.slice(0, 5),
					categories: res.filter((item) => item.categories),
				});
			})
			.catch((e) => {
				this.setState({ error: e.toJSON() });
				console.log(e);
			});
	}
	render() {
		return (
			<div className="blog-sidebar">
				<aside className="widget widget-trend-post">
					<h3 className="widget-title">Popular Posts</h3>
					{this.state.post.length >= 1 &&
						this.state.post.map((item) => {
							const imgurl = item.description;
							const match =
								typeof imgurl === "string" &&
								imgurl.match(/<img[^>]+src="([^">]+)"/);
							const imageSrc = match ? match[1] : null;
							return (
								<div className="popular-post">
									<a href="single-post.html">
										<img src={imageSrc} alt="" />
									</a>
									<h5>
										<a href="single-post.html">{item.title}</a>
									</h5>
									<span>{moment(item.pubDate).format("MMMM Do, YYYY")}</span>
								</div>
							);
						})}
				</aside>
			</div>
		);
	}
}

export default BlogSideBar;
