import React, { useState } from "react";
import thumb from "../../assets/images/Trust-8.svg";
import thumb1 from "../../assets/images/trust-9.png";
import thumb2 from "../../assets/images/trust.svg";
import FAQItem from "../Scams/FAQItem";

const TrustPage = () => {
	// State to track the selected topic
	const [selectedTopic, setSelectedTopic] = useState("topic1");
	const faqData = {
		topic1: [
			{
				question: "What does SOC stand for?",
				answer:
					"Service Organization Business. In a standards framework overseen by the American Institute of Certified Public Accountants (AICPA), suppliers are referred to by this word.",
			},
			{
				question: "What is an AICPA SOC 1 report?",
				answer: `An audit of a service organization's controls relevant to user entities' internal control over financial reporting yields a SOC 1 Report. Put simply, you would like us to have a reliable auditor prepare this report if our products generate financial calculations.`,
			},
			{
				question: "What is an AICPA SOC 2 report?",
				answer:
					"The outcome of an audit of a service organization's controls related to the risk areas of security, availability, processing integrity, confidentiality, or privacy is a SOC 2 report. Not every SOC 2 covers all of these, so make sure to check for which are relevant to your organization and that you ask for the report to guarantee it has what you need.",
			},
			{
				question:
					"What is the difference between a Type I and Type II SOC audit?",
				answer: `It is crucial to comprehend this: Type I and Type II audits both "report on the appropriateness of the control design to achieve the related control objectives included in the description and the fairness of the presentation of management's description of the service organization's system." Yet, a Type I is "at a specific date," which makes it typically faster, less expensive, and provides you with a lot more LESS assurance that the controls are operating. In comparison, Type II is undertaken “throughout a specified period”, hence is more complete (during a Type II audit, the controls are validated to be working over a period in order to guarantee they are working consistently). Type II reports typically take longer and are more costly to conduct, but the result is more mature controls. Lukka only conducts Type II audits for BOTH SOC 1 and SOC 2 reports.`,
			},
			{
				question: "What period of time does the report cover?",
				answer: "12 Month Period Updated Annually",
			},
		],
		topic2: [
			{
				question: "What is the scope of a SOC 1 report?",
				answer:
					"Controls at a Service Organization Relevant to User Entities' Internal Control over Financial Reporting (ICFR) are the subject of SOC 1 reports. These reports are specifically designed to meet the needs of entities that use service organizations (user entities) and the CPAs that audit the user entities' financial statements (user auditors), in assessing the impact of the controls at the service organization on the user entities' financial statements. They are prepared in accordance with AT-C section 320, Reporting on an Examination of Controls at a Service Organization Relevant to User Entities’ Internal Control Over Financial Reporting.",
			},
			{
				question: "Are all SOC 1 audits the same?",
				answer: `No, each one is unique to the particular Service Organization and varies greatly. It is advisable to enquire about the SOC auditor of any Service Organization you intend to use as a vendor, obtain a copy of the SOC report, determine if the Type I or Type II (Type II is strongly preferable), and find out how long the Service Organization has been conducting SOC audits.`,
			},
			{
				question: "What time period is associated with Lukka’s Type II audits?",
				answer:
					"Lukka conducts SOC 1 Type II audits annually. For the portion of the year outside of testing periods, we offer bridge letters to customers upon request.",
			},
		],
		topic3: [
			{
				question: "What is the scope of a SOC 2 report?",
				answer: [
					"According to the AICPA, SOC 2 reports focus on the controls at a service organization related to Security, Availability, Processing Integrity, Confidentiality, and Privacy. These reports are designed to provide a wide range of users with detailed insights and assurance about the controls in place. Specifically, they address the security, availability, and integrity of systems that process users' data, as well as the confidentiality and privacy of the information handled by those systems.",
					"These reports are valuable for enhancing oversight within the organization, supporting vendor management programs, strengthening internal corporate governance and risk management efforts, and ensuring compliance with regulatory requirements.",
				],
			},
			{
				question: "Are all SOC 2 audits the same?",
				answer: `No, each one is unique to the particular Service Organization and varies greatly. Particularly, any or all of the five risk domain areas—security, availability, processing integrity, confidentiality, and privacy—may or may not be included in SOC 2 reports. It is advisable to enquire about the SOC auditor of any Service Organization you intend to use as a vendor, obtain a copy of the SOC report, determine if the Type I or Type II (Type II is strongly preferable), and find out how long the Service Organization has been conducting SOC audits.`,
			},
			{
				question: "What time period is associated with Lukka’s Type II audits?",
				answer:
					"Lukka conducts SOC 2 Type II audits annually. For the portion of the year outside of testing periods, we offer bridge letters to customers upon request.",
			},
		],
		topic4: [
			{
				question: "What does ISO/IEC 27001 stand for?",
				answer:
					"The acronym ISO/IEC represents the International Electrotechnical Commission and the International Organization for Standardization. The ISO/IEC 27000 family of standards is published by the joint ISO/IEC committee. The most well-known standard in the world for information security management systems among the twelve standards in the 27000 family is ISO/IEC 27001.",
			},
			{
				question: "Who can issue an ISO 27001 Certification?",
				answer: [
					"Only institutions licensed by accreditation bodies, known as certification bodies, are authorized to conduct certification audits and determine whether a company's information security management system complies with ISO/IEC 27001 can issue ISO 27001 certifications. The British Standards Institution (BSI), an independent institution, is the source of Lukka's ISO/IEC Certification.",
					"BSI was the world’s first National Standards Body and was a vital role in the development of later standards bodies. BSI first released BS 7799 in 1995 in order to offer a thorough set of controls that included information security best practices. After revisions and updates, BS 7799 was changed to ISO/IEC 27001 in October 2005.",
				],
			},
		],

		// Add more FAQ data as needed
	};
	// Content and images for each topic
	const topics = {
		topic1: {
			faq: "faq1",
			title: "AICPA SOC Reporting",
			text: "Reports from the AICPA SOC are crucial for managing risk associated with service organizations, often known as vendors. The impartial standards-setter for SOC Audits is the AICPA. Using this Service Organizations controls framework, an independent auditor often performs SOC audits once a year. The audits end with a report outlining the findings. This makes it easier for any company that uses a service organization as a vendor to rely on these reports in order to reduce the risks related to the items they use.",
			img: thumb,
		},
		topic2: {
			faq: "faq2",
			title: "SOC 1 Type II",
			text: "Suave Reconn was one of the first company serving the crypto market to perform an AICPA SOC 1 Type II audit in 2019 and subsequently a SOC 2 Type II in 2020. See how we pay for renowned auditors to assess our technology risk so you don't have to.",
			img: thumb,
		},
		topic3: {
			faq: "faq3",
			title: "SOC 2 Type II",
			text: "Suave Reconn was the first company serving the crypto market to perform an AICPA SOC 1 Type II audit in 2019 and subsequently a SOC 2 Type II in 2020. See how we pay for renowned auditors to assess our technology risk so you don't have to.",
			img: thumb,
		},
		topic4: {
			faq: "faq4",
			title: "ISO/IEC 27001",
			text: "Data is everything to a business and data that you can trust is an incredibly valuable asset to help drive decision making. Data needs to be protected against various risks, whether natural, accidental, or deliberate. In order to manage these risks, an effective information security management system (ISMS) must be established. An ISMS focuses on the people, processes, organizational structures, and systems that help to ensure the security, confidentiality, integrity, and availability of information. To ensure that best practices are in place when implementing an ISMS, an organization should obtain an ISO/IEC 27001 Certification.  ISO/IEC 27001 is one of the most widely recognized and internationally accepted frameworks on information security. It is an auditable standard that provides requirements for the overall management of information security. To receive an ISO/IEC 27001 Certification, an organization must demonstrate to an independent certification body that it has implemented its ISMS in accordance with the requirements outlined in the ISO/IEC 27001 standard. Being ISO/IEC 27001 Certified means that an organization is dedicated to putting risk management first. ",
			img: thumb1,
		},
		topic5: {
			title: "Clout Providers",
			text: "Suave Reconn systems operate on AWS cloud platforms allowing for agility and instant elasticity in the most stable and secure environments available today.   AWS Cloud systems power hundreds of thousands of businesses in 190 countries around the world with world-class data center locations in the U.S., Europe, Brazil, Singapore, Japan, and Australia. ",
			img: thumb2,
		},
		topic6: {
			title: "Suave Reconn Prime Governance",
			text: "Suave Reconn Prime is a pricing and valuation pricing data service that utilizes a proprietary methodology designed for the unique characteristics of the crypto ecosystem. The methodology is designed to align to guidelines such as those described under IFRS and US GAAP, which specify generally accepted rules for making a determination of Fair Market Value (FMV) for an asset that is exchanged in liquid markets. In addition to Suave Reconn Prime utilizing this methodology, it is governed transparently and has clearly deﬁned standard operating procedures. The governance structure provides for regular oversight by qualified senior members of the Suave Reconn organization and all changes to the methodology or inputs are conducted in a controlled manner that is recorded for audibility. Procedures are both automated and managed by a team of data professionals that manage mechanisms to handle escalations and other inquiries with a focus on data quality, reliability, consistency, preserving independence, preventing conflicts of interest, and ensuring detailed audit logs. The Suave Reconn Prime Pricing Integrity Manual is available upon request to users of Suave Reconn Prime as are other attestation reports such as Suave Reconn AICPA SOC 1 Type II and SOC 2 Type II reports.",
			img: null,
		}, // No image for topic 5
	};

	// Function to handle click on topics
	const handleTopicClick = (topic) => {
		setSelectedTopic(topic);
	};

	return (
		<div className="container">
			<div>
				{/* Navigation for topics */}
				<nav style={styles.nav}>
					{Object.keys(topics).map((topic, index) => (
						<button
							key={index}
							onClick={() => handleTopicClick(topic)}
							style={{
								...styles.button,
								backgroundColor:
									selectedTopic === topic ? "#4CAF50" : "#f1f1f1",
								color: selectedTopic === topic ? "#fff" : "#000",
							}}
						>
							{topics[topic].title} {/* Access the title of each topic */}
						</button>
					))}
				</nav>

				{/* Display content based on selected topic */}
				<div style={styles.contentWrapper}>
					<div style={styles.textContent}>
						<h2>{topics[selectedTopic].title}</h2>
						<p>{topics[selectedTopic].text}</p>
					</div>

					{/* Display image if the topic has one */}
					{topics[selectedTopic].img && (
						<div style={styles.imageContainer}>
							<img
								src={topics[selectedTopic].img}
								alt={`Content for ${selectedTopic}`}
								style={styles.image}
							/>
						</div>
					)}
				</div>
				{/* Map over the FAQ data for the selected topic */}
				{faqData[selectedTopic] &&
					faqData[selectedTopic].map((faq, index) => (
						<FAQItem key={index} question={faq.question} answer={faq.answer} />
					))}
			</div>
		</div>
	);
};

// Styles
const styles = {
	nav: {
		display: "flex",
		justifyContent: "space-around",
		marginBottom: "20px",
		flexWrap: "wrap", // Ensures buttons stack on smaller screens
	},
	button: {
		padding: "10px 13px",
		cursor: "pointer",
		border: "none",
		borderRadius: "5px",
		outline: "none",
		transition: "background-color 0.3s ease",
		marginBottom: "10px", // Adds spacing between buttons on mobile
	},
	contentWrapper: {
		display: "flex",
		textAlign: "left",
		margin: "auto",
		justifyContent: "space-between",
		flexWrap: "wrap", // Responsive layout on smaller screens
		padding: "20px",
	},
	textContent: {
		flex: 1, // Allows the text to take up available space
		marginRight: "20px",
		minWidth: "250px", // Ensures text section is not too small on smaller screens
	},
	imageContainer: {
		flexShrink: 0, // Prevents the image from shrinking
	},
	image: {
		width: "300px",
		height: "auto",
		borderRadius: "10px",
	},
};

export default TrustPage;
