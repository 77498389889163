import React from "react";
import thumb from "../../assets/images/7.webp";
import { Link, useLocation } from "react-router-dom";
function LitigationService() {
	return (
		<>
			<section className="appie-service-details-area pt-100 pb-100">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<div className="service-details-sidebar mr-50">
								<div className="service-category-widget">
									<h5>Services</h5>
									<br />
									<ul>
										<li>
											<Link to="/service/crypto-tracing">
												Crypto Tracing & Investigation
											</Link>
										</li>
										<li>
											<Link to="/service/digital-asset-recovery">
												Digital Asset Recovery
											</Link>
										</li>
										<li>
											<Link to="/service/charge-back-assistance">
												Charge Back Assistance{" "}
											</Link>
										</li>
										<li>
											<Link to="/service/due-diligence">Due Diligence </Link>
										</li>
										<li>
											<Link to="/service/wallet-tracing">Wallet Tracing</Link>
										</li>
										<li>
											<Link to="/service/blockchain-forensic">
												Blockchain Forensic and Intelligence
											</Link>
										</li>
										<li>
											<Link to="/service/litigation-support">
												Litigation Support
											</Link>
										</li>
										<li>
											<Link to="/service/OSINT">
												Open Source Intelligence (OSINT)
											</Link>
										</li>
										<li>
											<Link to="/service/cybercrime-investigation-service">
												Cybercrime Investigation Service
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-8">
							<div className="service-details-content">
								<div className="thumb">
									<img src={thumb} alt="" />
								</div>
								<div className="content">
									<p>
										As the landscape of cryptocurrency continues to expand, the
										legal complexities associated with it grow increasingly
										intricate. At <b>Suave Reconn</b>, we specialize in
										providing Litigation Support Services that address the
										multifaceted challenges of crypto-asset disputes. Our team
										is equipped to guide you through every aspect of the legal
										process, ensuring that you have the expertise and support
										needed to secure a successful resolution in the face of
										jurisdictional, cross-border, and regulatory hurdles
									</p>
									<p>
										Litigation support encompasses a range of services designed
										to assist in legal proceedings involving crypto-assets.
										Effective litigation support is crucial in navigating the
										intricate landscape of crypto-asset disputes, especially
										when dealing with jurisdictional issues, compliance issues,
										and handling procedural matters. At <b>Suave Reconn</b>, we
										are committed to providing the expert support you need to
										manage these challenges and achieve successful outcomes in
										your legal proceedings.
									</p>
									<h3 className="title">Our Key Litigation Support Services</h3>
									<ul class="custom-list">
										<li>
											<b>Jurisdictional Issues:</b>
											<p>
												Determining the appropriate legal jurisdiction for a
												crypto-asset dispute is critical. Our experts analyze
												the relevant legal frameworks to identify the most
												suitable jurisdiction for your case. We help in
												strategizing to ensure that your legal claims are
												addressed in the most advantageous venue.
											</p>
										</li>
										<li>
											<b>Cross-Border Transactions:</b>
											<p>
												Crypto-assets often involve transactions that span
												multiple countries. We provide assistance in tracing and
												recovering assets across borders, overcoming the
												challenges posed by differing legal systems,
												regulations, and enforcement mechanisms.
											</p>
										</li>
										<li>
											<b>Freezing Orders:</b>
											<p>
												When immediate action is needed to prevent the
												dissipation of assets, a freezing order (or injunction)
												can be essential. We assist in the preparation and
												application of freezing orders to secure assets and
												prevent further transactions that could jeopardize your
												case.
											</p>
										</li>
										<li>
											<b>Subpoenas:</b>
											<p>
												In legal disputes, subpoenas may be required to compel
												the production of documents or testimony. We guide you
												through the process of issuing and enforcing subpoenas,
												ensuring that all relevant evidence is obtained and
												presented effectively.
											</p>
										</li>
										<li>
											<b>Undefended List Suits:</b>
											<p>
												If a defendant fails to respond to legal proceedings, a
												suit may be placed on an undefended list, allowing for a
												default judgment. We manage these situations by
												preparing necessary documentation and presenting your
												case for a favorable outcome.
											</p>
										</li>
										<li>
											<b>Motions Ex Parte:</b>
											<p>
												In certain cases, motions can be filed ex parte (without
												notifying the opposing party) to obtain urgent relief.
												We assist in drafting and filing these motions to secure
												immediate actions needed to protect your interests.
											</p>
										</li>
										<li>
											<b>Regulatory Compliance:</b>
											<p>
												Navigating the complex regulatory landscape of
												crypto-assets is crucial for any legal dispute. We
												provide guidance on compliance with applicable laws and
												regulations, ensuring that all aspects of your case
												adhere to legal standards and help avoid potential
												pitfalls.
											</p>
										</li>
										<li>
											<b>Expert Witness:</b>
											<p>
												Our team includes seasoned professionals who can serve
												as expert witnesses. They provide authoritative
												testimony on technical aspects of blockchain technology
												and crypto transactions, helping to clarify complex
												issues for the court.
											</p>
										</li>
									</ul>

									<h3 className="title">Why Choose Us?</h3>
									<p>
										At <b>Suave Reconn</b>, we offer unparalleled expertise in
										both the legal and technical aspects of crypto-assets,
										ensuring comprehensive support for complex jurisdictional
										and cross-border issues. Our strategic approach and
										full-spectrum services, including urgent motions and
										freezing orders, are tailored to your specific needs. We
										maintain the highest standards of confidentiality and
										integrity while delivering prompt and effective solutions,
										ensuring that every aspect of your case is managed with
										professionalism and efficiency.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default LitigationService;
