import React from "react";
import serviceThumb from "../../assets/images/service-thumb-1.png";

function ServicesHomeEight() {
	return (
		<>
			<section
				className="appie-services-2-area appie-services-8-area  pb-55"
				id="service"
			>
				<div className="container">
					<div className="row align-items-end">
						<div className="col-lg-6 col-md-8">
							<div className="appie-section-title">
								<h3 className="appie-title">How We Work</h3>
							</div>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-lg-12">
							<div className="row">
								<div className="col-lg-6 col-md-6">
									<div
										className="
                    appie-single-service-2 appie-single-service-about
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
										data-wow-duration="2000ms"
										data-wow-delay="200ms"
									>
										<div className="icon">
											<i className="fal fa-briefcase" />
										</div>
										<h4 className="title">Consultation & Case Review </h4>
										<p>
											Once you reach out to us, our team conducts a thorough
											evaluation of your situation. We assess the nature of the
											scam or loss, identify the key recovery challenges, and
											devise a strategy for your specific case.
										</p>
									</div>
								</div>
								<div className="col-lg-6 col-md-6">
									<div
										className="
                    appie-single-service-2 appie-single-service-about
                    item-2
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
										data-wow-duration="2000ms"
										data-wow-delay="400ms"
									>
										<div className="icon">
											<i className="fal fa-tv" />
										</div>
										<h4 className="title">Investigation & Asset Tracking </h4>
										<p>
											Our specialists use blockchain forensics and financial
											tracking tools to locate your lost cryptocurrency or
											assets. For non-crypto cases, our financial fraud analysts
											dive deep into tracing the misappropriated funds or
											assets.
										</p>
									</div>
								</div>
								<div className="col-lg-6 col-md-6">
									<div
										className="
                    appie-single-service-2 appie-single-service-about
                    item-3
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
										data-wow-duration="2000ms"
										data-wow-delay="600ms"
									>
										<div className="icon">
											<i className="fal fa-bolt" />
										</div>
										<h4 className="title">Recovery & Resolution </h4>
										<p>
											After successfully locating the assets, we proceed with
											the recovery process, adhering to legal and security
											standards. Once recovered, we return the assets directly
											to you.
										</p>
									</div>
								</div>
								<div className="col-lg-6 col-md-6">
									<div
										className="
                    appie-single-service-2 appie-single-service-about
                    item-4
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
										data-wow-duration="2000ms"
										data-wow-delay="200ms"
									>
										<div className="icon">
											<i className="fal fa-lock" />
										</div>
										<h4 className="title"> Ongoing Protection </h4>
										<p>
											In addition to recovery services, we offer ongoing asset
											management and security consultations to ensure your
											digital assets remain safe from future threats.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default ServicesHomeEight;
