import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import ProjectHomeThree from '../HomeThree/ProjectHomeThree';
import FooterHomeThree from '../HomeThree/FooterHomeThree';
import Drawer from '../Mobile/Drawer';
import HeaderAbout from './HeaderAbout';
import HeroAbout from './HeroAbout';
import DetailsTerms from './DetailsTerms';


function Terms() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderAbout drawer={drawer} action={drawerAction.toggle} />
            <HeroAbout />
            <DetailsTerms />
            <ProjectHomeThree />
            <FooterHomeThree className="appie-footer-about-area" />
            <BackToTop />
        </>
    );
}

export default Terms;
