import React, { useState } from "react";
import one from "../../assets/images/t1.jpeg";
import two from "../../assets/images/t2.jpg";
import three from "../../assets/images/t3.jpg";
import four from "../../assets/images/t4.jpg";
import five from "../../assets/images/t5.jpg";
function PricingHomeTwo() {
	const [activeSection, setActiveSection] = useState(1); // Default to Section 1

	const handleSwitch = (sectionNumber, e) => {
		e.preventDefault();
		setActiveSection(sectionNumber);
	};

	const sectionData = [
		{
            handle: "Track",
			title: " Proven Track Record ",
			description:
				"Having successfully recovered millions in lost cryptocurrency, stolen assets, and misappropriated funds, our track records speak for itself. Whether it’s large-scale corporate losses to personal crypto wallet breaches, we have the expertise and experience to handle it adequately.",
			image: one
		},
		{
            handle: "Guarantee",
			title: "No Recovery, No Fee Guarantee",
			description:
				"We operate on a strict no recovery, no fee policy. This means that unless we successfully recover your lost assets, you owe us nothing. We are confident in our process and stand by our results.",
			image: two
		},
		{
            handle: "Care",
			title: "Global Reach, Personal Care",
			description:
				"We serve clients across the globe, from individuals to large corporations. Despite our worldwide reach, we treat each case with the same level of personal care and dedication. Whether you're in need of crypto scam recovery, financial fraud resolution, or digital asset management, you can count on us.",
			image: three ,
		},
		{
            handle: "Techniques",
			title: " Advanced Recovery Techniques",
			description:
				"Our team leverages the latest advancements in blockchain forensics, cybersecurity, and data analysis to ensure that we can track down lost cryptocurrency and assets, even when it seems impossible. We utilize proprietary software and investigative methods that are both cutting-edge and secure.",
			image: four ,
		},
		{
            handle: "Transparency",
			title:
				"Full Transparency and Confidentiality",
			description:
				"We understand that dealing with financial loss can be stressful, which is why we prioritize full transparency throughout the recovery process. We keep you informed of every step while ensuring that your personal and financial information is handled with the utmost confidentiality and security.",
			image: five ,
		},
	];

	return (
		<>
			<section className="appie-pricing-2-area pb-100 mg-top">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="appie-section-title text-center">
								<h3 className="appie-title">Why Choose Suave Reconn?</h3>
								<p>
									Here are the reasons we truly stand out, setting ourselves
									apart and ensuring exceptional value for our clients.
								</p>
								<div className="appie-pricing-tab-btn">
									<ul className="nav nav-pills mb-3" role="tablist">
										{sectionData.map((section, index) => (
											<li className="nav-item" role="presentation" key={index}>
												<a
													onClick={(e) => handleSwitch(index + 1, e)}
													className={`nav-link ${
														activeSection === index + 1 ? "active" : ""
													}`}
													href="#"
													type="button"
													role="tab"
												>
													{section.handle}
												</a>
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="tab-content" id="pills-tabContent">
								<div className={`tab-pane fade active show`}>
									<div className="row justify-content-center">
										<div className="col-lg-5 col-md-6">
											<img
												src={sectionData[activeSection - 1].image}
												alt={sectionData[activeSection - 1].title}
												className="img-fluid"
											/>
										</div>
										<div className="col-lg-5 col-md-6">
											<div className="pricing-content">
												<h6>{sectionData[activeSection - 1].title}</h6>
												<p>{sectionData[activeSection - 1].description}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default PricingHomeTwo;
