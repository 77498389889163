import React, { useEffect } from "react";
import logo from "../../assets/images/logo.png";
import StickyMenu from "../../lib/StickyMenu";
import Navigation from "../Navigation";

function HeaderService({ action }) {
	useEffect(() => {
		StickyMenu();
	});
	return (
		<>
			<header className="appie-header-area appie-sticky">
				<div className="container">
					<div className="header-nav-box">
						<div className="row align-items-center">
							<div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
								<div className="appie-logo-box">
									<a href="/">
										<img width={"95px"} src={logo} alt="" />
										{/* <span className="logo_t">Suave Reconn</span> */}
									</a>
								</div>
							</div>
							<div className="col-lg-9 col-md-1 col-sm-1 order-3 order-sm-2">
								<div className="poly-header-new appie-header-main-menu">
									<Navigation />
								</div>
							</div>
							<div className="col-lg-4  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
								<div className="appie-btn-box text-right">
									<div
										onClick={(e) => action(e)}
										className="toggle-btn ml-30 canvas_open d-lg-none d-block"
									>
										<i className="fa fa-bars"></i>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
		</>
	);
}

export default HeaderService;
