import React, { useRef } from 'react';
import HomeDisqus from '../HomeFive/HomeDisqus';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

function TestimonialHomeOne() {

    return (
        <section className="appie-testimonial-area pt-100 pb-160" id="testimonial">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="appie-testimonial-slider" style={{ position: 'relative' }}>
                        <HomeDisqus /> 
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TestimonialHomeOne;
