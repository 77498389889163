import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
function Drawer({ drawer, action }) {
	const [itemOpen, setOpen] = useState(false);
	const [itemSize, setSize] = useState("0px");
	const [item, setItem] = useState("home");
	const handler = (e, value) => {
		e.preventDefault();
		const getItems = document.querySelectorAll(`#${value} li`).length;
		if (getItems > 0) {
			setSize(`${43 * getItems}px`);
			setItem(value);
		}
		setOpen(!itemOpen);
		if (itemOpen) {
			setSize(`0px`);
		}
	};
	return (
		<>
			<div
				onClick={(e) => action(e)}
				className={`off_canvars_overlay ${drawer ? "active" : ""}`}
			></div>
			<div className="offcanvas_menu">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12">
							<div
								className={`offcanvas_menu_wrapper ${drawer ? "active" : ""}`}
							>
								<div className="canvas_close">
									<a style={{ cursor: "pointer" }} onClick={(e) => action(e)}>
										<i className="fa fa-times"></i>
									</a>
								</div>
								<div className="offcanvas-brand text-center mb-40">
									<a href="/">
										<img width={"95px"} src={logo} alt="" />
										{/* <span className="logo_t">Suave Reconn</span> */}
									</a>
								</div>
								<div id="menu" className="text-left ">
									<ul className="offcanvas_main_menu">
										<li
											onClick={(e) => handler(e, "about")}
											id="about"
											className="menu-item-has-children active"
										>
											<li>
												<Link to="/about-us">About Us</Link>
											</li>
										</li>
										<li
											onClick={(e) => handler(e, "service")}
											id="service"
											className="menu-item-has-children active"
										>
											<span className="menu-expand">
												<i className="fa fa-angle-down"></i>
											</span>
											<a style={{ cursor: "pointer" }}>Service</a>
											<ul
												className="sub-menu"
												style={{
													height: item === "service" ? itemSize : "0px",
												}}
											>
												<li>
													<Link to="/service/crypto-tracing">
														Crypto Tracing & Investigation
													</Link>
												</li>
												<li>
													<Link to="/service/digital-asset-recovery">
														Digital Asset Recovery
													</Link>
												</li>
												<li>
													<Link to="/service/charge-back-assistance">
														Charge Back Assistance{" "}
													</Link>
												</li>
												<li>
													<Link to="/service/due-diligence">
														Due Diligence{" "}
													</Link>
												</li>
												<li>
													<Link to="/service/wallet-tracing">
														Wallet Tracing
													</Link>
												</li>
												<li>
													<Link to="/service/blockchain-forensic">
														Blockchain Forensic and Intelligence
													</Link>
												</li>
												<li>
													<Link to="/service/litigation-support">
														Litigation Support
													</Link>
												</li>
												<li>
													<Link to="/service/OSINT">
														Open Source Intelligence (OSINT)
													</Link>
												</li>
												<li>
													<Link to="/service/cybercrime-investigation-service">
														Cybercrime Investigation Service
													</Link>
												</li>
											</ul>
										</li>

										<li
											onClick={(e) => handler(e, "scams")}
											id="scams"
											className="menu-item-has-children active"
										>
											<span className="menu-expand">
												<i className="fa fa-angle-down"></i>
											</span>
											<a style={{ cursor: "pointer" }}>Scams</a>
											<ul
												className="sub-menu"
												style={{
													height: item === "scams" ? itemSize : "0px",
												}}
											>
												<li>
													<Link to="/scams/credit-card-fraud">
														Credit Card Fraud
													</Link>
												</li>
												<li>
													<Link to="/scams/cryptocurrency-scam">
														Cryptocurrency Scam
													</Link>
												</li>
												<li>
													<Link to="/scams/online-romance-scam">
														Online Romance Scam
													</Link>
												</li>

												<li>
													<Link to="/scams/pig-butchering-scam">
														Pig Butchering Scam
													</Link>
												</li>
												<li>
													<Link to="/scams/online-banking-scam">
														Online Banking Scam
													</Link>
												</li>
												<li>
													<Link to="/scams/investment-scam">
														Investment Scam
													</Link>
												</li>
												<li>
													<Link to="/scams/phishing-scam">Phishing Scam</Link>
												</li>
												<li>
													<Link to="/scams/forex-scam">Forex Scam</Link>
												</li>

												<li>
													<Link to="/scams/bitcoin-scam">Bitcoin Scam</Link>
												</li>
											</ul>
										</li>

										<li
											onClick={(e) => handler(e, "scam-recovery")}
											id="scam-recovery"
											className="menu-item-has-children active"
										>
											<span className="menu-expand">
												<i className="fa fa-angle-down"></i>
											</span>
											<a style={{ cursor: "pointer" }}>Scam Recovery</a>
											<ul
												className="sub-menu"
												style={{
													height: item === "scam-recovery" ? itemSize : "0px",
												}}
											>
												<li>
													<Link to="/scams-recovery/credit-card-fraud-recovery">
														Credit Card Fraud Recovery
													</Link>
												</li>
												<li>
													<Link to="/scams-recovery/cryptocurrency-scam-recovery">
														Cryptocurrency Scam Recovery
													</Link>
												</li>
												<li>
													<Link to="/scams-recovery/online-romance-scam-recovery">
														Online Romance Scam Recovery
													</Link>
												</li>

												<li>
													<Link to="/scams-recovery/pig-butchering-scam-recovery">
														Pig Butchering Scam Recovery
													</Link>
												</li>
												<li>
													<Link to="/scams-recovery/online-banking-scam-recovery">
														Online Banking Scam Recovery
													</Link>
												</li>
												<li>
													<Link to="/scams-recovery/investment-scam-recovery">
														Investment Scam Recovery
													</Link>
												</li>
												<li>
													<Link to="/scams-recovery/phishing-scam-recovery">
														Phishing Scam Recovery
													</Link>
												</li>
												<li>
													<Link to="/scams-recovery/forex-scam-recovery">
														Forex Scam Recovery
													</Link>
												</li>

												<li>
													<Link to="/scams-recovery/bitcoin-scam-recovery">
														Bitcoin Scam Recovery
													</Link>
												</li>
											</ul>
										</li>
										<li
											onClick={(e) => handler(e, "blog")}
											id="blog"
											className="menu-item-has-children active"
										>
											<li>
												<Link to="/news">News</Link>
											</li>
										</li>
										<li
											onClick={(e) => handler(e, "analysistool")}
											id="analysistool"
											className="menu-item-has-children active"
										>
											<Link to="/analysistool">Analysis Tool</Link>
										</li>
										<li
											onClick={(e) => handler(e, "scamsites")}
											id="scamsites"
											className="menu-item-has-children active"
										>
											<Link to="/scamsites">Scam Sites</Link>
										</li>
										<li
											onClick={(e) => handler(e, "contact")}
											id="contact"
											className="menu-item-has-children active"
										>
											<Link to="/contact">Contact Us</Link>
										</li>
									</ul>
								</div>
								<div className="footer-widget-info">
									<ul>
										<li>
											<a href="mailto:contact@suavereconn.org">
												<i className="fal fa-envelope"></i>
												contact@suavereconn.org
											</a>
										</li>
										<li>
											<a href="tel:+1-970-579-1822">
												<i className="fal fa-phone"></i>
												+1 (970) 579 1822
											</a>
										</li>
										<li>
											<a>
												<i className="fal fa-map-marker-alt"></i> 1400 16th
												Street, Downtown Denver, Colorado, 80202
											</a>
										</li>
									</ul>
									<br />
									<Link to="/start" className="main-btn ml-30">
										Start Reclaim!
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Drawer;
