import React from "react";
import { Link } from "react-router-dom";

function Navigation() {
	return (
		<>
			<ul>
				<li>
					<Link to="/about-us">About Us</Link>
				</li>
				<li>
					<a style={{ cursor: "pointer" }}>
						Service <i className="fal fa-angle-down" />
					</a>
					<ul className="sub-menu">
						<li>
							<Link to="/service/crypto-tracing">
								Crypto Tracing & Investigation
							</Link>
						</li>
						<li>
							<Link to="/service/digital-asset-recovery">
								Digital Asset Recovery
							</Link>
						</li>
						<li>
							<Link to="/service/charge-back-assistance">
								Charge Back Assistance{" "}
							</Link>
						</li>
						<li>
							<Link to="/service/due-diligence">Due Diligence </Link>
						</li>
						<li>
							<Link to="/service/wallet-tracing">
								Wallet Tracing
							</Link>
						</li>
						<li>
							<Link to="/service/blockchain-forensic">
							Blockchain Forensic and Intelligence
							</Link>
						</li>
						<li>
							<Link to="/service/litigation-support">
							Litigation Support
							</Link>
						</li>
						<li>
							<Link to="/service/OSINT">
							Open Source Intelligence (OSINT)
							</Link>
						</li>
						<li>
							<Link to="/service/cybercrime-investigation-service">
							Cybercrime Investigation Service
							</Link>
						</li>
					</ul>
				</li>

				<li>
					<a id="scams" style={{ cursor: "pointer" }}>
						Scams <i className="fal fa-angle-down" />
					</a>
					<ul className="sub-menu">
						<li>
							<Link to="/scams/credit-card-fraud">Credit Card Fraud</Link>
						</li>
						<li>
							<Link to="/scams/cryptocurrency-scam">Cryptocurrency Scam</Link>
						</li>
						<li>
							<Link to="/scams/online-romance-scam">Online Romance Scam</Link>
						</li>
					
						<li>
							<Link to="/scams/pig-butchering-scam">Pig Butchering Scam</Link>
						</li>
						<li>
							<Link to="/scams/online-banking-scam">Online Banking Scam</Link>
						</li>
						<li>
							<Link to="/scams/investment-scam">Investment Scam</Link>
						</li>
						<li>
							<Link to="/scams/phishing-scam">Phishing Scam</Link>
						</li>
						<li>
							<Link to="/scams/forex-scam">
								Forex Scam
							</Link>
						</li>
					
						<li>
							<Link to="/scams/bitcoin-scam">Bitcoin Scam</Link>
						</li>
						
					</ul>
				</li>

				<li>
					<a style={{ cursor: "pointer" }}>
						Scam Recovery
						<i className="fal fa-angle-down" />
					</a>
					<ul className="sub-menu">
						<li>
							<Link to="/scams-recovery/credit-card-fraud-recovery">Credit Card Fraud Recovery</Link>
						</li>
						<li>
							<Link to="/scams-recovery/cryptocurrency-scam-recovery">Cryptocurrency Scam Recovery</Link>
						</li>
						<li>
							<Link to="/scams-recovery/online-romance-scam-recovery">Online Romance Scam Recovery</Link>
						</li>
					
						<li>
							<Link to="/scams-recovery/pig-butchering-scam-recovery">Pig Butchering Scam Recovery</Link>
						</li>
						<li>
							<Link to="/scams-recovery/online-banking-scam-recovery">Online Banking Scam Recovery</Link>
						</li>
						<li>
							<Link to="/scams-recovery/investment-scam-recovery">Investment Scam Recovery</Link>
						</li>
						<li>
							<Link to="/scams-recovery/phishing-scam-recovery">Phishing Scam Recovery</Link>
						</li>
						<li>
							<Link to="/scams-recovery/forex-scam-recovery">
								Forex Scam Recovery
							</Link>
						</li>
					
						<li>
							<Link to="/scams-recovery/bitcoin-scam-recovery">Bitcoin Scam Recovery</Link>
						</li>	
					</ul>
				</li>
				<li>
					<Link to="/news">News</Link>
				</li>

				<li>
					<Link to="/analysistool">Analysis Tool</Link>
				</li>
				<li>
					<Link to="/scamsites">Scam Sites</Link>
				</li>
			</ul>
		</>
	);
}

export default Navigation;
