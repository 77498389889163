import React from "react";
import thumb from "../../assets/images/Trust.png";
function HeroTrust() {
	return (
		<>
			<div className="newb appie-page-title-area appie-page-service-title-area">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="appie-page-title-item">
								<span>Home/trust-center</span>
								<div className="trust">
									<div className="trust-cont">
										<h3 className="title trustt">Trust Center</h3>
										<br />
										Data security is our top focus at <b>Suave Reconn</b>.
									</div>
								</div>
								<br />
								<img src={thumb} width="60%" alt="trust" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default HeroTrust;
