import React from "react";
import useToggle from "../../Hooks/useToggle";
import HeaderAbout from "../AboutUs/HeaderAbout";
import BackToTop from "../BackToTop";
import FooterHomeThree from "../HomeThree/FooterHomeThree";
import ProjectHomeThree from "../HomeThree/ProjectHomeThree";
import Drawer from "../Mobile/Drawer";
import Atool from "./Atool";

function AnalysisTool() {
	const [drawer, drawerAction] = useToggle(false);
	return (
		<>
			<Drawer drawer={drawer} action={drawerAction.toggle} />
			<HeaderAbout drawer={drawer} action={drawerAction.toggle} />
			<Atool />
			<ProjectHomeThree />
			<FooterHomeThree className="appie-footer-about-area" />
			<BackToTop />
		</>
	);
}

export default AnalysisTool;
