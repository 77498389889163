import React from "react";
import { Link, useLocation } from "react-router-dom";
function DetailsTerms() {
	const location = useLocation();
	return (
		<>
			<section className="appie-service-details-area pt-100 pb-100">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<div className="service-details-sidebar mr-50">
								<div className="service-download-widget">
									<Link to="/about-us">
										<i className="fal fa-file"></i>
										<span>About Us</span>
									</Link>
								</div>
								<div className="service-download-widget">
									<Link to="/terms">
										<i className="fal fa-file"></i>
										<span>{`Terms & Conditions`}</span>
									</Link>
								</div>
								<div className="service-download-widget">
									<Link to="/privacy">
										<i className="fal fa-file"></i>
										<span>Privacy Policy</span>
									</Link>
								</div>
								<div className="service-download-widget">
									<Link to="/legal">
										<i className="fal fa-file"></i>
										<span>Legal</span>
									</Link>
								</div>
								<div className="service-download-widget">
									<Link to="/trust-center">
										<i className="fal fa-file"></i>
										<span>Trust Center</span>
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-8">
							<div className="service-details-content">
								<div className="content">
									<h3 className="title">Introduction.</h3>
									<p>
										These Website Standard Terms And Conditions (these "Terms"
										or "Website Standard Terms And Conditions"), which can be
										found on this webpage, govern your use of this website,
										including all pages within it (collectively referred to as
										"Website" below). These Terms apply to your use of this
										Website in full force and effect, and by using this Website,
										you fully accept all of the terms and conditions stated
										herein in their entirety. If you disagree with any of these
										Website Standard Terms And Conditions, you must not use this
										Website.
									</p>
									<p>
										This Website is not intended for use by minors (defined as
										those under the age of 18), and you must not use it if you
										are a minor.
									</p>
									<h3 className="title">Link to Other websites.</h3>
									<p>
										Our Service may contain links to third-party websites or
										services that <b>Suave Reconn</b> does not own or control.
									</p>
									<p>
										<b>Suave Reconn</b> has no control over, and assumes no
										responsibility for, the content, privacy policies, or any
										damage or loss caused or alleged to be caused by or in
										connection with the use of or reliance on any such content,
										goods, or practices of any third-party web sites or
										services. You also realize and accept that{" "}
										<b>Suave Reconn</b> is not responsible for the content or
										services accessible on or through any of these sites.
									</p>

									<h3 className="title">NonLegal Services.</h3>
									<p>
										<b>Suave Reconn</b> does not represent clients and does not
										provide legal advice or representation. <b>Suave Reconn</b>{" "}
										will guide and advise the client through the process of
										transaction cancellation and refund.
									</p>
									<h3 className="title">Intellectual Property Rights.</h3>
									<p>
										Except for content you own, which you may have chosen to
										include on this Website, <b>Suave Reconn</b> is the owner of
										all content on this Website.
									</p>
									<p>
										All intellectual property and material contained in this
										Website is owned by and/or its licensors, and all such
										rights are reserved.
									</p>
									<p>
										You are only allowed a limited licence to view the material
										on this Website, which is subject to the restrictions set
										forth in these Terms.
									</p>

									<h3 className="title">Restrictions.</h3>
									<p>
										Using this Website in a manner that violates relevant laws
										and regulations, or in a manner that harms the Website, or
										any person or business organisation;
									</p>
									<p>
										Data mining, data harvesting, data extraction, or any other
										similar activity in respect to or while using this Website;
									</p>
									<p>
										Sale of website materials, sublicenses, and / or other
										commercialization.
									</p>
									<h3 className="title">No warranties.</h3>
									<p>
										This Website is given "with no guarantees" with all
										shortcomings, and <b>Suave Reconn</b> makes no express or
										inferred portrayals or guarantees, of any sort connected
										with this Website or the materials contained on this
										Website. Also, nothing contained on this Website will be
										understood as giving counsel or exhortation to you.
									</p>

									<h3 className="title">Limitation of liability.</h3>
									<p>
										In no occasion will <b>Suave Reconn</b>, nor any of its
										officials, chiefs and workers, be at risk to you for
										anything emerging out of or in any capacity associated with
										your utilization of this Website, whether such obligation is
										under agreement, misdeed etc., and <b>Suave Reconn</b>,
										including its officials, chiefs and representatives will not
										be responsible for any aberrant, considerable or exceptional
										responsibility emerging out of or in any capacity connected
										with your utilization of this Website.
									</p>
									<h3 className="title">Severability.</h3>
									<p>
										In the event that any arrangement of these Terms is viewed
										as unenforceable or invalid under any material regulation,
										such unenforceability or deficiency will not ruin these
										Terms unenforceable or in general, and such arrangements
										will be erased without influencing the leftover arrangements
										thus.
									</p>
									<h3 className="title">Indemnification.</h3>
									<p>
										You thus reimburse to the furthest reaches{" "}
										<b>Suave Reconn</b> from and against all possible
										liabilities, costs, requests, reasons for activity, harms
										and costs (counting sensible lawyer's expenses) emerging out
										of or in any capacity connected with your break of any of
										the arrangements of these Terms.
									</p>
									<h3 className="title">The Services and fees.</h3>
									<p>
										The administrations given by <b>Suave Reconn</b> are molded
										by the client's particular on the help understanding. The
										fixed-charge paid for administrations delivered in the
										significant commitment with every client, isn't refundable
										and isn't molded by the outcomes. Retraction of the
										administrations under the agreement is as per the Israeli
										Consumer Protection Law (Transaction Cancellation), 2010,
										S.2(3), inside the initial 14 work days of the cycle before
										getting the service(s)
									</p>
									<h3 className="title">Entire Agreement.</h3>
									<p>
										These Terms, including any legitimate notification and
										disclaimers contained on this Website, comprise the whole
										arrangement between <b>Suave Reconn</b> and you according to
										your utilization of this Website, and override every single
										earlier understanding and understandings regarding something
										very similar.
									</p>
									<h3 className="title">{`Governing Law & Jurisdiction.`}</h3>
									<p>
										These Terms will be represented by and understood as per the
										laws of the State of Israel, and you submit to the non-elite
										purview of the state and government courts situated in
										Israel for the goal of any debates.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default DetailsTerms;
