import React from "react";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";

class Blogs extends React.Component {
	state = {
		post: {},
	};

	mediumURL =
		"https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@suavereconn";

	componentDidMount() {
		axios
			.get(this.mediumURL)

			.then((data) => {
				console.log(data.data);
				const res = data.data.items; //This is an array with the content. No feed, no info about author etc..
				this.setState({
					post: res,
				});
			})
			.catch((e) => {
				this.setState({ error: e.toJSON() });
				console.log(e);
			});
	}

	render() {
		return (
			<>
				<div className="row">
					{this.state.post.length >= 1 &&
						this.state.post.map((item) => {
							const imgurl = item.description;
							const match =
								typeof imgurl === "string" &&
								imgurl.match(/<img[^>]+src="([^">]+)"/);
							const imageSrc = match ? match[1] : null;
							return (
								<div className="col-lg-6">
									<div className="post-item-1">
										<img src={imageSrc} alt="pics" />
										<div className="b-post-details">
											<div className="bp-meta">
												<a href="#">
													<i className="fal fa-clock"></i>
													{moment(item.pubDate).format("MMMM Do, YYYY")}
												</a>
											</div>
											<h3>
												<Link to={`/news/${item.title}`}>{item.title}</Link>
											</h3>
											<Link to={`/news/${item.title}`}>
												<a className="read-more">
													Read More<i className="fal fa-arrow-right"></i>
												</a>
											</Link>
										</div>
									</div>
								</div>
							);
						})}
				</div>
				<div className="row">
					<div className="col-lg-12">
						<div className="bisylms-pagination">
							<span className="current">01</span>
							<a className="next" href="#">
								next<i className="fal fa-arrow-right"></i>
							</a>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Blogs;
