import React, { useEffect, useState } from "react";
import thumb from "../../assets/images/traffic-thumb.png";
import { Link } from "react-router-dom";
import sal from "sal.js";
import "sal.js/dist/sal.css";
import PopupVideo from "../PopupVideo";

function TrafficHomeOne() {
	const [showVideo, setVideoValue] = useState(false);
	const handleShowVideo = (e) => {
		e.preventDefault();
		setVideoValue(!showVideo);
	};
	useEffect(() => {
		// Initialize SAL when the component mounts
		sal();
	}, []);
	return (
		<>
			{showVideo && (
				<PopupVideo
					videoSrc="//www.youtube.com/embed/BbJqnVnLmpo"
					handler={(e) => handleShowVideo(e)}
				/>
			)}
			<section className="appie-traffic-area pt-50 pb-180 appie-faq-8-area">
				<div className="container">
					<div className="row">
						<div className="col-lg-7">
							<div
								className="appie-traffic-title"
								data-sal="slide-right"
								data-sal-duration="1000"
							>
								<span>About Us</span>
								<h3 className="title pt-20">
									We help you, we lead you, and we recover your money.
								</h3>
								<p>
									We are your strategic partners in financial recovery. Our
									experts blend urgency, clarity, and precision to tackle your
									financial challenges head-on. We’re dedicated to turning the
									tide in your favor. Our proactive approach ensures swift,
									transparent, and meticulously planned solutions to secure your
									assets and restore your peace of mind.
								</p>
							</div>
							<div className="row">
								<div className="col-lg-6 col-md-6">
									<div className="appie-traffic-service mb-30">
										<div className="icon">
											<i className="fal fa-check" />
										</div>

										<p>Answers that are clear and timely.</p>
									</div>
								</div>
								<div className="col-lg-6 col-md-6">
									<div className="appie-traffic-service mb-30">
										<div className="icon">
											<i className="fal fa-check" />
										</div>
										<p>Has a 90% success rate worldwide.</p>
									</div>
								</div>
								<div className="col-lg-6 col-md-6">
									<div className="appie-traffic-service">
										<div className="icon">
											<i className="fal fa-check" />
										</div>
										<p>Experts in Digital Forensics</p>
									</div>
								</div>
								<div className="col-lg-6 col-md-6">
									<div className="appie-traffic-service">
										<div className="icon">
											<i className="fal fa-check" />
										</div>
										<p>Recovering money with unwavering quality .</p>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="traffic-btn mt-50">
										<Link to="/about-us" className="main-btn">
											Learn More <i className="fal fa-arrow-right" />
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="home-play-box">
					<div className="play-btn">
						<a
							onClick={(e) => handleShowVideo(e)}
							className="appie-video-popup"
							href="https://www.youtube.com/watch?v=EE7NqzhMDms"
						>
							<i className="fas fa-play" />
						</a>
					</div>
				</div>
			</section>
		</>
	);
}

export default TrafficHomeOne;
