import React, { useState } from "react";
import axios from "axios";
import AnalysisD from "./AnalysisD";
import Loader from "../Helper/Loader";

function Atool() {
	const [analysis, setAnalysis] = useState(null);
	const [wotAnalysis, setWotAnalysis] = useState(null); // For WOT response
	const [domain, setDomain] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	// Handle input changes
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		if (name === "analysis") {
			setDomain(value);
		}
	};

	// Form submission handler
	const onSamaSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			// First API call (WHOIS)
			const whoisOptions = {
				method: "GET",
				url: "https://ip2whois-whois-information-lookup.p.rapidapi.com/",
				params: {
					key: "5A0DD7F4B301AC41DBF47DBD33596413",
					domain: domain,
					format: "json",
				},
				headers: {
					"X-RapidAPI-Key":
						"925fa1ead1mshd30c99d956455acp1fbc2ajsn685a41b6d8b7",
					"X-RapidAPI-Host": "ip2whois-whois-information-lookup.p.rapidapi.com",
				},
			};
			const whoisResponse = await axios.request(whoisOptions);

			// Second API call (WOT)
			const wotOptions = {
				method: "GET",
				url: `https://wot-web-risk-and-safe-browsing.p.rapidapi.com/targets?t=${domain}`,
				headers: {
					"x-rapidapi-key":
						"6f213714f4msh59da95c02ac1d42p1246c3jsn523a1de18a2e",
					"x-rapidapi-host": "wot-web-risk-and-safe-browsing.p.rapidapi.com",
				},
			};
			const wotResponse = await axios.request(wotOptions);

			// Set both API responses into state
			setAnalysis(whoisResponse.data);
			setWotAnalysis(wotResponse.data[0].safety);
			console.log(wotResponse.data[0]); // Add this line after receiving the wotAnalysis response

			setLoading(false);
		} catch (error) {
			setError(error);
			setLoading(false);
		}
	};

	// Helper function to interpret WOT results
	const getSafetyMessage = (status) => {
		if (status === "UNKNOWN") {
			return "Website reputation is extremely low.";
		} else if (status === "SAFE") {
			return "This website is safe.";
		} else if (status === "SUSPICIOUS") {
			return "This website is suspicious!";
		} else {
			return "Website safety information is unavailable.";
		}
	};

	return (
		<>
			{loading ? (
				<div className={`appie-loader ${loading ? "active" : ""}`}>
					<Loader />
				</div>
			) : (
				<section className="appie-service-details-area pt-100 pb-100">
					<div className="container">
						<div className="web_analysis">
							<h1 className="heads">Web Analysis Tool</h1>
							<p>
								The Web Analysis Tool provides you with basic information for
								identifying websites and applications, helping you determine if
								a site is a scam or not.
							</p>
							<form onSubmit={onSamaSubmit} method="GET">
								<div className="col-md-12 contact-form">
									<input
										type="text"
										name="analysis"
										placeholder="Enter Website Here (Do not add http)"
										onChange={(e) => handleInputChange(e)}
									/>
									<input type="submit" name="submit" value="Scan Website" />
								</div>
							</form>

							<div className="analysis">
								{wotAnalysis && (
									<div className="wot-analysis">
										<p>
										
											Safety Check for {analysis && analysis.domain}:{" "}
											<h3>{getSafetyMessage(wotAnalysis.status)}</h3>
										</p>
									</div>
								)}

								{/* {analysis && (
									<AnalysisD
										domain={analysis.domain}
										created={analysis.create_date}
										updated={analysis.update_date}
										expires={analysis.expire_date}
										nameservers={analysis.nameservers}
										admin={analysis.admin}
										country={analysis.registrant.country}
										region={analysis.registrant.region}
									/>
								)} */}

								{analysis && (
									<div className="rawwho">
										<h1>How to Spot a Scam Website</h1>
										<p>
											1. Domain Creation: Businesses that claim to have been
											established for several years must have a domain name
											registration that corresponds to that year unless they
											claim to have changed it. However, even in cases where
											names and other information have been changed as a result
											of internal disputes, such businesses are still recognized
											as legitimate. Note: For instance, if a firm claims to
											have been established in 2020 but hasn't made any changes
											to the domain, a registration alert will be raised. Also,
											keep in mind that only newly registered domains have their
											updated and created dates match; established domains have
											created and updated dates separated by at least two
											months.
										</p>
										<p>
											2. Country and Region: Legitimate businesses allow you to
											see their regions; any domain that only provides you with
											the country of registration is a red flag. The region
											provided must correspond to the location Head Office
											provided on the website. For example, country of
											registration: FL US. The above example contains its region
											which must match the address and zip from the website.
										</p>
										<p>
											3. DNS Details: Copy just one nameserver, for instance,
											ns1.dns-parking.com, and search on Google. It should show
											companies that provide the nameserver and must also be
											well-known companies like godaddy, namecheap, hostinger,
											or even their own company's nameservers, for example
											google.com, Nameservers:ns1.google.com. Organizations
											choose the best hosting, not some low-cost hosting or
											domain suppliers.
										</p>
										<h5>Raw Whois:</h5>
										<h6>Domain: {analysis.domain}</h6>
										<h6>Domain id: {analysis.domain_id}</h6>
										<h6>Creation Date: {analysis.create_date}</h6>
										<h6>Updated Date: {analysis.update_date}</h6>
										<h6>Registry Expiry Date: {analysis.expire_date}</h6>
										<h6>Status: {analysis.status}</h6>
										<h6>Whois Server: {analysis.whois_server}</h6>
										<h6>Admin City: {analysis.admin?.city}</h6>
										<h6>Admin country: {analysis.admin?.country}</h6>
										<h6>Admin Email: {analysis.admin?.email}</h6>
										<h6>Admin Fax: {analysis.admin?.fax}</h6>
										<h6>Admin Name: {analysis.admin?.name}</h6>
										<h6>Admin Organisation: {analysis.admin?.organization}</h6>
										<h6>Admin Phone: {analysis.admin?.phone}</h6>
										<h6>Admin Region: {analysis.admin?.region}</h6>
										<h6>
											Admin Street Address: {analysis.admin?.street_address}
										</h6>
										<h6>Admin Zip: {analysis.admin?.zip_code}</h6>
										<h6>Registrant City: {analysis.registrant?.city}</h6>
										<h6>Registrant country: {analysis.registrant?.country}</h6>
										<h6>Registrant Email: {analysis.registrant?.email}</h6>
										<h6>Registrant Fax: {analysis.registrant?.fax}</h6>
										<h6>Registrant Name: {analysis.registrant?.name}</h6>
										<h6>
											Registrant Organisation:{" "}
											{analysis.registrant?.organization}
										</h6>
										<h6>Registrant Phone: {analysis.registrant?.phone}</h6>
										<h6>Registrant Region: {analysis.registrant?.region}</h6>
										<h6>
											Registrant Street Address:{" "}
											{analysis.registrant?.street_address}
										</h6>
										<h6>Registrant Zip: {analysis.registrant?.zip_code}</h6>
										<h6>Registrar iana id: {analysis.registrar?.iana_id}</h6>
										<h6>Registrar Name: {analysis.registrar?.name}</h6>
										<h6>Registrar Url: {analysis.registrar?.url}</h6>
										<h6>Tech City: {analysis.tech?.city}</h6>
										<h6>Tech country: {analysis.tech?.country}</h6>
										<h6>Tech Email: {analysis.tech?.email}</h6>
										<h6>Tech Fax: {analysis.tech?.fax}</h6>
										<h6>Tech Name: {analysis.tech?.name}</h6>
										<h6>Tech Organisation: {analysis.tech?.organization}</h6>
										<h6>Tech Phone: {analysis.tech?.phone}</h6>
										<h6>Tech Region: {analysis.tech?.region}</h6>
										<h6>
											Tech Street Address: {analysis.tech?.street_address}
										</h6>
										<h6>Tech Zip: {analysis.tech?.zip_code}</h6>
										{analysis.nameservers?.map((items, index) => (
											<h6 key={index}>NameServers: {items}</h6>
										))}
									</div>
								)}
							</div>
						</div>
					</div>
				</section>
			)}
		</>
	);
}

export default Atool;
