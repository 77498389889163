import React, { useState } from "react";


const FAQItem = ({ question, answer }) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleAnswer = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div
			className="faq-item"
			onClick={toggleAnswer}
			style={{ cursor: "pointer", marginBottom: "10px" }}
		>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<p>
					<b>{question}</b>
				</p>
				{/* Font Awesome Arrow Icon (toggle between down and up) */}
				<i
					className={`fas ${isOpen ? "fa-chevron-up" : "fa-chevron-down"}`}
					style={{ fontSize: "29px", transition: "transform 0.3s ease", marginRight: "18px" }}
				></i>
			</div>
			<div
				style={{
					height: isOpen ? "auto" : "0",
					overflow: "hidden",
					transition: "height 0.3s ease, opacity 0.3s ease",
					opacity: isOpen ? 1 : 0,
				}}
			>
				<p>
					{answer}
				</p>
			</div>
		</div>
	);
};

export default FAQItem;
