import React, { Component } from "react";
import Disqus from "disqus-react";

export default class HomeDisqus extends Component {
	render() {
		const disqusShortname = "suave-reconn";
		const disqusConfig = {
			url: "https://suavereconn.org/",
			identifier: "dsq-count-scr",
			title: "",
		};

		return (
			<div className="article-container">
				<Disqus.DiscussionEmbed
					shortname={disqusShortname}
					config={disqusConfig}
				/>
			</div>
		);
	}
}
