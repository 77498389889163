import React, { useEffect } from "react";
import featureThumb from "../../assets/images/features-thumb-2.png";
import shape13 from "../../assets/images/shape/shape-13.png";
import shape14 from "../../assets/images/shape/shape-14.png";
import shape15 from "../../assets/images/shape/shape-15.png";
import sal from "sal.js";
import "sal.js/dist/sal.css";

function FeaturesHomeTwo() {
	useEffect(() => {
		// Initialize SAL when the component mounts
		sal();
	}, []);
	return (
		<>
			<section className="appie-features-area-2 pt-90 pb-100" id="features">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="appie-section-title appie-section-title-2 text-center">
								<h3>
									Essential Scams You Need to Avoid and How We Can Help
									<br />
								</h3>
								<p>
									Scammers employ a myriad of tactics to deceive and exploit.
									From fraudulent credit card charges to sophisticated
									cryptocurrency schemes, the internet is rife with deceptive
									practices. Whether you've encountered a rogue broker or a
									deceitful romance scam, we're here to help you navigate out of
									these traps and reclaim what’s rightfully yours.
								</p>
							</div>
						</div>
					</div>
					<div className="row mt-30 align-items-center">
						<div className="col-lg-6">
							<div className="appie-features-boxes">
								<div
									className="appie-features-box-item"
									data-sal="slide-right"
									data-sal-duration="200"
								>
									<h4 className="title">Credit Card Fraud</h4>
									<p>Unauthorized charges can be contested and resolved.</p>
								</div>
								<div
									className="appie-features-box-item item"
									data-sal="slide-right"
									data-sal-duration="500"
								>
									<h4 className="title">Cryptocurrency Scam</h4>
									<p>Secure your digital assets from fraudulent schemes.</p>
								</div>
								<div
									className="appie-features-box-item item"
									data-sal="slide-right"
									data-sal-duration="800"
								>
									<h4 className="title">Forex Scam</h4>
									<p>Ensure safe and legitimate currency trading.</p>
								</div>
								<div
									className="appie-features-box-item item"
									data-sal="slide-right"
									data-sal-duration="1100"
								>
									<h4 className="title">Investment Scam</h4>
									<p>Protect your investments from deceitful practices.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="appie-features-boxes">
								<div
									className="appie-features-box-item"
									data-sal="slide-left"
									data-sal-duration="200"
								>
									<h4 className="title">Online Banking Scam</h4>
									<p>
										Protect your financial assets from deceptive practices that
										exploit vulnerabilities in online banking systems.
									</p>
								</div>
								<div
									className="appie-features-box-item item"
									data-sal="slide-left"
									data-sal-duration="500"
								>
									<h4 className="title">Online Romance Scam</h4>
									<p>Safeguard yourself from deceptive romantic schemes.</p>
								</div>
								<div
									className="appie-features-box-item item"
									data-sal="slide-left"
									data-sal-duration="800"
								>
									<h4 className="title">Pig Butchering Scam</h4>
									<p>Avoid getting lured by false investment promises.</p>
								</div>
								<div
									className="appie-features-box-item item"
									data-sal="slide-left"
									data-sal-duration="1100"
								>
									<h4 className="title">Bitcoin Scam</h4>
									<p>
										Safeguard your investments by identifying and avoiding
										fraudulent schemes designed to steal your cryptocurrency.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default FeaturesHomeTwo;
