import React from "react";
import useToggle from "../../Hooks/useToggle";
import BackToTop from "../BackToTop";
import ProjectHomeThree from "../HomeThree/ProjectHomeThree";
import FooterHomeThree from "../HomeThree/FooterHomeThree";
import Drawer from "../Mobile/Drawer";
import HeaderAbout from "./HeaderAbout";
import HeroAbout from "./HeroAbout";
import DetailsAbout from "./DetailsAbout";
import FaqHomeOne from "../HomeEight/FaqHomeEight";
import PricingHomeTwo from "../HomeTwo/PricingHomeTwo";
import ServicesHomeTwo from "../HomeTwo/ServicesHomeTwo";
import ServicesHomeEight from "../HomeEight/ServicesHomeEight";
import AboutSection from "./AboutSection";
import AboutSectionLast from "./AboutSectionLast";

function AboutUs() {
	const [drawer, drawerAction] = useToggle(false);
	return (
		<>
			<Drawer drawer={drawer} action={drawerAction.toggle} />
			<HeaderAbout drawer={drawer} action={drawerAction.toggle} />
			<HeroAbout />
			<DetailsAbout />
			<AboutSection />
			<FaqHomeOne />
			<PricingHomeTwo />
			<ServicesHomeEight />
			<ServicesHomeTwo />
			<AboutSectionLast />
			<ProjectHomeThree />
			<FooterHomeThree className="appie-footer-about-area" />
			<BackToTop />
		</>
	);
}

export default AboutUs;
