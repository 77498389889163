import React from "react";
import { Link, useLocation } from "react-router-dom";
import thumb from "../../assets/images/kkk.png";
import thumb1 from "../../assets/images/kkk1.png";
function HeroService() {
	const location = useLocation();
	const titles = location.pathname.startsWith("/service") ? thumb : null;
	const titleselect =
		location.pathname === "/service/crypto-tracing"
			? "Crypto Tracing & Investigation"
			: location.pathname === "/service/digital-asset-recovery"
			? `Digital Asset Recovery`
			: location.pathname === "/service/charge-back-assistance"
			? "Charge Back Assistance"
			: location.pathname === "/service/due-diligence"
			? "Due Diligence"
			: location.pathname === "/service/wallet-tracing"
			? `Wallet Tracing`
			: location.pathname === "/service/blockchain-forensic"
			? "Blockchain Forensic and Intelligence"
			: location.pathname === "/service/litigation-support"
			? `Litigation Support`
			: location.pathname === "/service/OSINT"
			? "Open Source Intelligence (OSINT)"
			: location.pathname === "/service/cybercrime-investigation-service"
			? "Cybercrime Investigation Service"
			: location.pathname === "/scams/credit-card-fraud"
			? `Credit Card Fraud`
			: location.pathname === "/scams/cryptocurrency-scam"
			? "Cryptocurrency Scam"
			: location.pathname === "/scams/bitcoin-scam"
			? "Bitcoin Scam"
			: location.pathname === "/scams/online-banking-scam"
			? "Online Banking Scam"
			: location.pathname === "/scams/online-romance-scam"
			? `Online Romance Scam`
			: location.pathname === "/scams/investment-scam"
			? "Investment Scam"
			: location.pathname === "/scams/pig-butchering-scam"
			? "Pig Butchering Scam"
			: location.pathname === "/scams/phishing-scam"
			? `Phishing Scams`
			: location.pathname === "/scams/forex-scam"
			? "Forex Scam"
			: location.pathname === "/scams-recovery/credit-card-fraud-recovery"
			? `Credit Card Fraud Recovery`
			: location.pathname === "/scams-recovery/cryptocurrency-scam-recovery"
			? "Cryptocurrency Scam Recovery"
			: location.pathname === "/scams-recovery/bitcoin-scam-recovery"
			? "Bitcoin Scam Recovery"
			: location.pathname === "/scams-recovery/online-banking-scam-recovery"
			? "Online Banking Scam Recovery"
			: location.pathname === "/scams-recovery/online-romance-scam-recovery"
			? `Online Romance Scam Recovery`
			: location.pathname === "/scams-recovery/investment-scam-recovery"
			? "Investment Scam Recovery"
			: location.pathname === "/scams-recovery/pig-butchering-scam-recovery"
			? "Pig Butchering Scam Recovery"
			: location.pathname === "/scams-recovery/phishing-scam-recovery"
			? `Phishing Scams Recovery`
			: location.pathname === "/scams-recovery/forex-scam-recovery"
			? "Forex Scam Recovery"
			: null;

	console.log(titles);

	return (
		<>
			<div className="newb appie-page-title-area appie-page-service-title-area">
				<div className="container">
					<div className="row newf">
						<div className="col-lg-12">
							<div className="appie-page-title-item">
								<span>Home{location.pathname}</span>
								<h3 className="title srctitle">{titleselect}</h3>
								<br />
								<div>
									<a class="main-btn" href="/start">
										Recover your Funds Now
									</a>
								</div>
							</div>
						</div>
						<div className="col-lg-6 imgflld">
							{titles ? (
								<img src={thumb} alt="Service Thumbnail" />
							) : (
								<img src={thumb1} alt="Service Thumbnail" />
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default HeroService;
