import React, { useState } from "react";

function FaqHomeOne({ className }) {
	const [showQues, setQues] = useState(1);
	const openQuestion = (value) => {
		setQues(value);
	};
	return (
		<>
			<section className={`appie-faq-area pb-95 ${className || ""}`}>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="appie-section-title text-center pt-50">
								<h3 className="appie-title">Frequently asked questions</h3>
								<p>
									We have questions and answers in our FAQ, but if you want to
									talk to us directly, we're just a phone call or email away. We
									have consultants available whenever you need them!
								</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-6">
							<div
								className="faq-accordion wow fadeInRight mt-30"
								data-wow-duration="1500ms"
							>
								<div
									className="accrodion-grp animated fadeIn faq-accrodion wow"
									data-wow-duration="1500ms"
									data-grp-name="faq-accrodion"
								>
									<div
										onClick={() => openQuestion(1)}
										className={`accrodion ${showQues === 1 ? "active" : ""}`}
									>
										<div className="accrodion-inner">
											<div className="accrodion-title">
												<h4>How fund recovery works and what it is?</h4>
											</div>
											<div
												className="accrodion-content"
												style={{
													display: showQues === 1 ? "block" : "none",
												}}
											>
												<div className="inner">
													<p>
														The process of getting your money back from con
														artists or dishonest sellers is called fund
														recovery, also referred to as investment recovery or
														resource recovery. Identification of the problem,
														thorough investigation, and the recovery of funds
														and assets are the three major steps in the fund
														recovery process. The proper legal actions are taken
														after the merchant or broker has been verified.
													</p>
												</div>
											</div>
										</div>
									</div>
									<div
										onClick={() => openQuestion(2)}
										className={`accrodion ${showQues === 2 ? "active" : ""}`}
									>
										<div className="accrodion-inner">
											<div className="accrodion-title">
												<h4>
													How do I know if I’ve been targeted by a Bitcoin scam?
												</h4>
											</div>
											<div
												className="accrodion-content"
												style={{
													display: showQues === 2 ? "block" : "none",
												}}
											>
												<div className="inner">
													<p>
														Bitcoin scams often involve fraudulent schemes that
														trick you into sending your cryptocurrency to
														illegitimate platforms or wallets. If you've
														experienced untrustworthy transactions or promises
														of unrealistic returns, you may have been scammed.
														We can help you trace and potentially recover your
														lost Bitcoin.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div
								className="faq-accordion wow fadeInRight mt-30"
								data-wow-duration="1500ms"
							>
								<div
									className="accrodion-grp animated fadeIn faq-accrodion wow"
									data-wow-duration="1500ms"
									data-grp-name="faq-accrodion"
								>
									<div
										onClick={() => openQuestion(3)}
										className={`accrodion ${showQues === 3 ? "active" : ""}`}
									>
										<div className="accrodion-inner">
											<div className="accrodion-title">
												<h4>Can you get your money back after a fraud?</h4>
											</div>
											<div
												className="accrodion-content"
												style={{
													display: showQues === 3 ? "block" : "none",
												}}
											>
												<div className="inner">
													<p>
														Yes. Scam victims' money can be retrieved with the
														help of professionals who are knowledgeable and
														skilled in this area. The complexity of the case
														generally determines the quantity that can be
														recovered. That clarifies the overall existence of
														Financial Fund Recovery and its role as a recovery
														organization.
													</p>
												</div>
											</div>
										</div>
									</div>
									<div
										onClick={() => openQuestion(4)}
										className={`accrodion ${showQues === 4 ? "active" : ""}`}
									>
										<div className="accrodion-inner">
											<div className="accrodion-title">
												<h4>
													What is a Pig Butchering scam, and how can I avoid it?
												</h4>
											</div>
											<div
												className="accrodion-content"
												style={{
													display: showQues === 4 ? "block" : "none",
												}}
											>
												<div className="inner">
													<p>
														A Pig Butchering scam is a sophisticated fraud where
														scammers build trust over time, often through
														personal or business relationships, before
														encouraging you to invest in fake ventures. The key
														to avoiding this scam is to be cautious of
														unsolicited investment opportunities and promises of
														high returns. If you’ve already fallen victim, we’re
														here to help.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-12">
							<div className="faq-text text-center pt-40">
								<p>
									Can't find an answer?{" "}
									<a href="mailto:contact@suavereconn.org">Email us</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default FaqHomeOne;
