import React, { useEffect } from "react";
import useToggle from "../../Hooks/useToggle";
import StickyMenu from "../../lib/StickyMenu";
import BackToTop from "../BackToTop";
import FooterHomeThree from "../HomeThree/FooterHomeThree";
import ProjectHomeThree from "../HomeThree/ProjectHomeThree";
import Drawer from "../Mobile/Drawer";
import HeaderService from "../Service/HeaderService";
import HeroService from "../Service/HeroService";
import { useLocation } from "react-router-dom";
import PigButcheringScam from "./PigButcheringScam";
import OnlineRomanceScam from "./OnlineRomanceScam";
import ForexScam from "./ForexScam";
import CreditCardFraud from "./CreditCardFraud";
import CryptocurrencyScam from "./CryptocurrencyScam";
import InvestmentScam from "./InvestmentScam";
import OnlineBankingScam from "./OnlineBankingScam";
import PhishingScam from "./PhishingScam";
import BitcoinScam from "./BitcoinScam";
// Import the new components for each servicem';

function ScamsMain() {
	useEffect(() => {
		StickyMenu();
	});
	const location = useLocation();

	// Determine the component to render based on the pathname
	const selectedScams =
		location.pathname === "/scams/pig-butchering-scam" ? (
			<PigButcheringScam />
		) : location.pathname === "/scams/investment-scam" ? (
			<InvestmentScam />
		) : location.pathname === "/scams/online-romance-scam" ? (
			<OnlineRomanceScam />
		) : location.pathname === "/scams/online-banking-scam" ? (
			<OnlineBankingScam />
		) : location.pathname === "/scams/cryptocurrency-scam" ? (
			<CryptocurrencyScam />
		) : location.pathname === "/scams/phishing-scam" ? (
			<PhishingScam />
		) : location.pathname === "/scams/forex-scam" ? (
			<ForexScam />
		) : location.pathname === "/scams/bitcoin-scam" ? (
			<BitcoinScam />
		) : location.pathname === "/scams/credit-card-fraud" ? (
			<CreditCardFraud />
		) : null; // Default component if no match
	const [drawer, drawerAction] = useToggle(false);
	return (
		<>
			<Drawer drawer={drawer} action={drawerAction.toggle} />
			<HeaderService action={drawerAction.toggle} />
			<HeroService />
			{selectedScams}
			<ProjectHomeThree />
			<FooterHomeThree />
			<BackToTop />
		</>
	);
}

export default ScamsMain;
