import React, { useState } from "react";
import CounterUpCom from "../../lib/CounterUpCom";
import PopupVideo from "../PopupVideo";

function FaqHomeEight() {
	const [showQuestion, setQuestion] = useState(0);
	const [showVideo, setVideoValue] = useState(false);
	const openQuestion = (e, value) => {
		e.preventDefault();
		setQuestion(value);
	};
	const handleShowVideo = (e) => {
		e.preventDefault();
		setVideoValue(!showVideo);
	};
	return (
		<>
			{showVideo && (
				<PopupVideo
					videoSrc="//www.youtube.com/embed/dZtY5YfeAh4"
					handler={(e) => handleShowVideo(e)}
				/>
			)}
			<div className="appie-faq-8-area pt-100 pb-100" id="counter">
				<div className="container">
					<div className="row">
						<div className="col-lg-5">
							<div className="appie-section-title">
								<h3 className="appie-title">Our Expertise</h3>
								<p>
									<b>Suave Reconn</b> is equipped with a world-class team of
									experts, including:
								</p>
							</div>
							<div
								className="faq-accordion wow fadeInRight mt-30"
								data-wow-duration="1500ms"
							>
								<div
									className="accrodion-grp wow fadeIn faq-accrodion"
									data-wow-duration="1500ms"
									data-grp-name="faq-accrodion"
								>
									<div
										className={`accrodion ${
											showQuestion === 0 ? "active" : ""
										}`}
										onClick={(e) => openQuestion(e, 0)}
									>
										<div className="accrodion-inner">
											<div className="accrodion-title">
												<h4>Blockchain Forensics Specialists</h4>
											</div>
											<div
												className="accrodion-content"
												style={{
													display: showQuestion === 0 ? "block" : "none",
												}}
											>
												<div className="inner">
													<p>
														With deep expertise in blockchain technology, our
														forensics team can analyze and recover crypto
														assets, even from highly sophisticated schemes.
													</p>
												</div>
											</div>
										</div>
									</div>
									<div
										className={`accrodion ${
											showQuestion === 1 ? "active" : ""
										}`}
										onClick={(e) => openQuestion(e, 1)}
									>
										<div className="accrodion-inner">
											<div className="accrodion-title">
												<h4>Cryptocurrency Investigators</h4>
											</div>
											<div
												className="accrodion-content"
												style={{
													display: showQuestion === 1 ? "block" : "none",
												}}
											>
												<div className="inner">
													<p>
														Our team of skilled investigators specializes in
														tracking and tracing lost or stolen cryptocurrency
														across complex blockchain networks, ensuring no
														digital asset goes unnoticed.
													</p>
												</div>
											</div>
										</div>
									</div>
									<div
										className={`accrodion ${
											showQuestion === 2 ? "active" : ""
										}`}
										onClick={(e) => openQuestion(e, 2)}
									>
										<div className="accrodion-inner">
											<div className="accrodion-title">
												<h4>Financial Fraud Analysts</h4>
											</div>
											<div
												className="accrodion-content"
												style={{
													display: showQuestion === 2 ? "block" : "none",
												}}
											>
												<div className="inner">
													<p>
														We have years of experience in identifying and
														investigating fraudulent schemes, including
														investment scams, Ponzi schemes, and phishing
														attacks.
													</p>
												</div>
											</div>
										</div>
									</div>
									<div
										className={`accrodion ${
											showQuestion === 3 ? "active" : ""
										}`}
										onClick={(e) => openQuestion(e, 3)}
									>
										<div className="accrodion-inner">
											<div className="accrodion-title">
												<h4>Legal Asset Recovery Experts</h4>
											</div>
											<div
												className="accrodion-content"
												style={{
													display: showQuestion === 3 ? "block" : "none",
												}}
											>
												<div className="inner">
													<p>
														Our legal team specializes in asset recovery laws
														and regulations, ensuring that every recovery effort
														is done within a secure and legally compliant
														framework
													</p>
												</div>
											</div>
										</div>
									</div>
									<div
										className={`accrodion ${
											showQuestion === 4 ? "active" : ""
										}`}
										onClick={(e) => openQuestion(e, 4)}
									>
										<div className="accrodion-inner">
											<div className="accrodion-title">
												<h4>Cybersecurity Specialists</h4>
											</div>
											<div
												className="accrodion-content"
												style={{
													display: showQuestion === 4 ? "block" : "none",
												}}
											>
												<div className="inner">
													<p>
														Our cybersecurity experts focus on safeguarding your
														digital assets from future threats and deploying. We
														assess vulnerabilities, enhance wallet security, and
														offer proactive solutions to prevent further losses.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="faq-play-box">
					<div className="play-btn">
						<a
							onClick={(e) => handleShowVideo(e)}
							className="appie-video-popup"
							href="https://www.youtube.com/watch?v=EE7NqzhMDms"
						>
							<i className="fas fa-play" />
						</a>
					</div>
					
				</div>
			</div>
		</>
	);
}

export default FaqHomeEight;
