import React from "react";
import story from "../../assets/images/team.jpg";
import FAQItem from "../Scams/FAQItem";
const AboutSectionLast = () => {
	const faqData = [
		{
			question: "Ready to Get Started?",
			answer:
				"If you’ve lost cryptocurrency, fallen victim to financial fraud, or caught in the web of a cyber-attack, Suave Reconn is here for you. Contact us today for a free consultation and take the first step toward recovering what’s rightfully yours.",
		},

		// Add more FAQ data as needed
	];
	return (
		<div className="about-container">
			{/* Our Story Section */}
			<div className="about-section">
				<div className="text-container">
					<h2>Our Team</h2>
					<p>
						At the core of <b>Suave Reconn</b> is a team of experienced
						professionals passionate about protecting your assets. We come from
						diverse backgrounds in blockchain technology, financial analysis,
						and legal asset recovery, allowing us to approach every case from
						multiple angles to ensure success.
					</p>
				</div>
				<div className="image-container">
					<img src={story} alt="Our Story" />
				</div>
			</div>

			{faqData.map((faq, index) => (
				<FAQItem key={index} question={faq.question} answer={faq.answer} />
			))}
		</div>
	);
};

export default AboutSectionLast;
