import React, { useEffect } from "react";
import useToggle from "../../Hooks/useToggle";
import StickyMenu from "../../lib/StickyMenu";
import BackToTop from "../BackToTop";
import FooterHomeThree from "../HomeThree/FooterHomeThree";
import ProjectHomeThree from "../HomeThree/ProjectHomeThree";
import Drawer from "../Mobile/Drawer";
import HeaderService from "../Service/HeaderService";
import HeroService from "../Service/HeroService";
import { useLocation } from "react-router-dom";
import PigButcheringScam from "./PigButcheringScamRecovery";
import OnlineRomanceScam from "./OnlineRomanceScamRecovery";
import ForexScam from "./ForexScamRecovery";
import CreditCardFraud from "./CreditCardFraudRecovery";
import CryptocurrencyScam from "./CryptocurrencyScamRecovery";
import InvestmentScam from "./InvestmentScamRecovery";
import OnlineBankingScam from "./OnlineBankingScamRecovery";
import PhishingScam from "./PhishingScamRecovery";
import BitcoinScam from "./BitcoinScamRecovery";
import PigButcheringScamRecovery from "./PigButcheringScamRecovery";
import InvestmentScamRecovery from "./InvestmentScamRecovery";
import OnlineRomanceScamRecovery from "./OnlineRomanceScamRecovery";
import OnlineBankingScamRecovery from "./OnlineBankingScamRecovery";
import CryptocurrencyScamRecovery from "./CryptocurrencyScamRecovery";
import PhishingScamRecovery from "./PhishingScamRecovery";
import ForexScamRecovery from "./ForexScamRecovery";
import BitcoinScamRecovery from "./BitcoinScamRecovery";
import CreditCardFraudRecovery from "./CreditCardFraudRecovery";
// Import the new components for each servicem';

function ScamsRecoveryMain() {
	useEffect(() => {
		StickyMenu();
	});
	const location = useLocation();

	// Determine the component to render based on the pathname
	const selectedScams =
		location.pathname === "/scams-recovery/pig-butchering-scam-recovery" ? (
			<PigButcheringScamRecovery />
		) : location.pathname === "/scams-recovery/investment-scam-recovery" ? (
			<InvestmentScamRecovery />
		) : location.pathname === "/scams-recovery/online-romance-scam-recovery" ? (
			<OnlineRomanceScamRecovery />
		) : location.pathname === "/scams-recovery/online-banking-scam-recovery" ? (
			<OnlineBankingScamRecovery />
		) : location.pathname === "/scams-recovery/cryptocurrency-scam-recovery" ? (
			<CryptocurrencyScamRecovery />
		) : location.pathname === "/scams-recovery/phishing-scam-recovery" ? (
			<PhishingScamRecovery />
		) : location.pathname === "/scams-recovery/forex-scam-recovery" ? (
			<ForexScamRecovery />
		) : location.pathname === "/scams-recovery/bitcoin-scam-recovery" ? (
			<BitcoinScamRecovery />
		) : location.pathname === "/scams-recovery/credit-card-fraud-recovery" ? (
			<CreditCardFraudRecovery />
		) : null; // Default component if no match
	const [drawer, drawerAction] = useToggle(false);
	return (
		<>
			<Drawer drawer={drawer} action={drawerAction.toggle} />
			<HeaderService action={drawerAction.toggle} />
			<HeroService />
			{selectedScams}
			<ProjectHomeThree />
			<FooterHomeThree />
			<BackToTop />
		</>
	);
}

export default ScamsRecoveryMain;
