import React, { useEffect } from "react";
import iconOne from "../../assets/images/icon/1.png";
import iconTwo from "../../assets/images/icon/2.png";
import iconThree from "../../assets/images/icon/3.png";
import iconFour from "../../assets/images/icon/4.png";
import iconFive from "../../assets/images/icon/5.png";
import { Link } from "react-router-dom";
import sal from "sal.js";
import "sal.js/dist/sal.css";

function ServicesHomeThree() {


	return (
		<>
			<div className="growthF">
				<div className="container GF">
					<div className="growthHead appie-about-content">
						<h1 className="title">
							Our services help victims of financial instability quickly regain
							their wealth.
						</h1>
						<p>
							We are qualified to assist you in our capacities as a consultant,
							recovery agency, legal aid, or simply with support and
							information, regardless of whether you lost money while acting as
							a trader, shopper, or online user.
						</p>
					</div>
					<div className="growthBodyTop">
						<span className="growthContent">
							<img src={iconOne} alt="wallet" width="90px" />
							<h3>Digital Asset Recovery</h3>
							<p>
								Our team of experts combines advanced blockchain forensics with
								OSINT, offering tailored solutions to recover stolen digital
								assets worldwide.
							</p>
						</span>
						<span className="growthContent">
							<img src={iconTwo} alt="wallet" width="90px" />
							<h3>Crypto Tracing & Investigation</h3>
							<p>
								Secure your digital assets with our expert crypto tracing and
								investigation services, designed to swiftly and efficiently
								reclaim lost or stolen funds from fraudulent crypto
								transactions.
							</p>
						</span>
						<span className="growthContent">
							<img src={iconThree} alt="wallet" width="90px" />
							<h3>Due Diligence</h3>
							<p>
								Discover hidden risks and make informed decisions with
								meticulous due diligence, ensuring transparency, accuracy, and
								compliance in every transaction.
							</p>
						</span>
					</div>
					<div className="growthBodyBottom">
						<span className="growthContent">
							<img src={iconFour} alt="wallet" width="90px" />
							<h3>Cybercrime Investigation</h3>
							<p className="ggold">
								Our cybercrime investigations tackle theft, fraud, and security
								breaches, focusing on recovering both digital and physical
								assets effectively.
							</p>
						</span>
						<span className="growthContent">
							<img src={iconFive} width="90px" alt="wallet" />
							<h3>Wallet Tracing</h3>
							<p>
								Our wallet tracing services focus on tracking and recovering the
								digital assets of individuals or businesses who have experienced
								theft, fraud, or loss of access to their cryptocurrencies.
							</p>
						</span>
					</div>
				</div>
			</div>
		</>
	);
}

export default ServicesHomeThree;
