import React, { useEffect } from "react";
import useToggle from "../../Hooks/useToggle";
import StickyMenu from "../../lib/StickyMenu";
import BackToTop from "../BackToTop";
import FooterHomeThree from "../HomeThree/FooterHomeThree";
import ProjectHomeThree from "../HomeThree/ProjectHomeThree";
import Drawer from "../Mobile/Drawer";
import HeaderService from "./HeaderService";
import HeroService from "./HeroService";
import { useLocation } from "react-router-dom";
// Import the new components for each service
import ChargeBackService from "./ChargeBackService";
import BlockchainForensicService from "./BlockchainForensicService";
import CryptoTracingService from "./CryptoTracingService";
import DigitalAssetRecoveryService from "./DigitalAssetRecoveryService";
import DueDiligenceService from "./DueDiligenceService";
import WalletTracingService from "./WalletTracingService";
import LitigationService from "./LitigationService";
import OSINTService from "./OSINTService";
import CyberInvestigationService from "./CyberInvestigationService";




function WalletFund() {
	useEffect(() => {
		StickyMenu();
	});

	const location = useLocation();

	// Determine the component to render based on the pathname
	const selectedService = location.pathname === "/service/crypto-tracing"
		? <CryptoTracingService />
		: location.pathname === "/service/digital-asset-recovery"
		? <DigitalAssetRecoveryService />
		: location.pathname === "/service/charge-back-assistance"
		? <ChargeBackService />
		: location.pathname === "/service/due-diligence"
		? <DueDiligenceService />
		: location.pathname === "/service/wallet-tracing"
		? <WalletTracingService />
		: location.pathname === "/service/blockchain-forensic"
		? <BlockchainForensicService />
		: location.pathname === "/service/litigation-support"
		? <LitigationService />
		: location.pathname === "/service/OSINT"
		? <OSINTService />
		: location.pathname === "/service/cybercrime-investigation-service"
		? <CyberInvestigationService />
		: null; // Default component if no match

	const [drawer, drawerAction] = useToggle(false);

	return (
		<>
			<Drawer drawer={drawer} action={drawerAction.toggle} />
			<HeaderService action={drawerAction.toggle} />
			<HeroService />
			{selectedService} {/* Render the selected component */}
			<ProjectHomeThree /> 
			<FooterHomeThree />
			<BackToTop />
		</>
	);
}

export default WalletFund;
