import React from "react";
import story from "../../assets/images/story.jpg";
import mission from "../../assets/images/mission.jpg";
import { Link } from "react-router-dom";
const AboutSection = () => {
	return (
		<div className="about-container">
			{/* Our Story Section */}
			<div className="about-section">
				<div className="text-container">
					<h2>Our Story</h2>
					<p>
						<b>Suave Reconn</b> was founded by a team of financial and
						blockchain experts who saw the devastating impact of{" "}
						<Link to="/scams/cryptocurrency-scam">cryptocurrency scams</Link>{" "}
						and <b>financial fraud</b> on individuals and businesses worldwide.
						Our founders recognized the lack of reliable recovery services for
						those who had lost their assets and decided to combine their
						expertise in <b>blockchain technology</b>, <b>financial security</b>
						, and <b>legal frameworks</b> to create a service that would truly
						make a difference.
					</p>
					<p>
						Since then, we’ve grown into a global leader in{" "}
						<b>crypto recovery</b> and
						<b>asset protection</b>, providing tailored solutions to clients
						across the globe. With each case, we continue to uphold our
						commitment to restoring financial security and peace of mind to
						those affected by fraud.
					</p>
				</div>
				<div className="image-container">
					<img src={story} alt="Our Story" />
				</div>
			</div>

			{/* Our Mission Section */}
			<div className="about-section reverse">
				<div className="image-container">
					<img src={mission} alt="Our Mission" />
				</div>
				<div className="text-container">
					<h2>Our Mission</h2>
					<p>
						Our mission is simple: to help individuals and businesses recover
						what is rightfully theirs. At <b>Suave Reconn</b>, we believe in the
						importance of <b>financial justice</b> and <b>security</b>. We are
						committed to offering solutions that not only recover your lost
						cryptocurrency and assets but also safeguard them from future
						threats.
					</p>
				</div>
			</div>
		</div>
	);
};

export default AboutSection;
