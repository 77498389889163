import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AboutUs from "./components/AboutUs";
import Privacy from "./components/AboutUs/Privacy";
import Terms from "./components/AboutUs/Terms";
import Legal from "./components/AboutUs/Legal";
import Contact from "./components/Contact";
import Error from "./components/Error";
import Loader from "./components/Helper/Loader";
import ScrollToTop from "./components/Helper/ScrollToTop";
import HomeFive from "./components/HomeFive";
import News from "./components/News";
import SingleNews from "./components/News/SingleNews";
import StartForm from "./components/StartForm";
import WalletFund from "./components/Service/WalletFund";
import AnalysisTool from "./components/AnalysisTools/Analysis";
import ScamSites from "./components/ScamSites/scamSites";
import Scaminfo from "./components/ScamSites/Scaminfo";
import ScamsMain from "./components/Scams/ScamsMain";
import ScamsRecoveryMain from "./components/ScamsRecovery/ScamsRecoveryMain";
import Trust from "./components/TrustPage/Trust";

function Routes() {
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		window.scrollTo(0, 0);
	});
	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 2000);
	});
	return (
		<>
			{loading && (
				<div className={`appie-loader ${loading ? "active" : ""}`}>
					<Loader />
				</div>
			)}
			<div className={`appie-visible ${loading === false ? "active" : ""}`}>
				<Router>
					<ScrollToTop>
						<Switch>
							<Route exact path="/" component={HomeFive} />
							<Route exact path="/news" component={News} />
							<Route path="/news/:id" component={SingleNews} />
							<Route exact path="/about-us" component={AboutUs} />
							<Route exact path="/privacy" component={Privacy} />
							<Route exact path="/terms" component={Terms} />
							<Route exact path="/legal" component={Legal} />
							<Route exact path="/contact" component={Contact} />
							<Route exact path="/start" component={StartForm} />
							<Route exact path="/analysistool" component={AnalysisTool} />
							<Route exact path="/scamsites" component={ScamSites} />
							<Route exact path="/scamsites/info" component={Scaminfo} />
							{/* services */}
							<Route
								exact
								path="/service/crypto-tracing"
								component={WalletFund}
							/>
							<Route
								exact
								path="/service/digital-asset-recovery"
								component={WalletFund}
							/>
							<Route
								exact
								path="/service/charge-back-assistance"
								component={WalletFund}
							/>
							<Route
								exact
								path="/service/due-diligence"
								component={WalletFund}
							/>
							<Route
								exact
								path="/service/wallet-tracing"
								component={WalletFund}
							/>
							<Route
								exact
								path="/service/blockchain-forensic"
								component={WalletFund}
							/>
							<Route
								exact
								path="/service/litigation-support"
								component={WalletFund}
							/>
							<Route exact path="/service/OSINT" component={WalletFund} />
							<Route
								exact
								path="/service/cybercrime-investigation-service"
								component={WalletFund}
							/>

							{/* End services */}

							{/* Scams */}
							<Route
								exact
								path="/scams/credit-card-fraud"
								component={ScamsMain}
							/>
							<Route
								exact
								path="/scams/cryptocurrency-scam"
								component={ScamsMain}
							/>
							<Route exact path="/scams/bitcoin-scam" component={ScamsMain} />
							<Route
								exact
								path="/scams/online-banking-scam"
								component={ScamsMain}
							/>
							<Route
								exact
								path="/scams/online-romance-scam"
								component={ScamsMain}
							/>
							<Route
								exact
								path="/scams/investment-scam"
								component={ScamsMain}
							/>
							<Route
								exact
								path="/scams/pig-butchering-scam"
								component={ScamsMain}
							/>
							<Route exact path="/scams/forex-scam" component={ScamsMain} />
							<Route exact path="/scams/phishing-scam" component={ScamsMain} />
							{/* End Scams */}

							{/* Scams Recovery*/}
							<Route
								exact
								path="/scams-recovery/credit-card-fraud-recovery"
								component={ScamsRecoveryMain}
							/>
							<Route
								exact
								path="/scams-recovery/cryptocurrency-scam-recovery"
								component={ScamsRecoveryMain}
							/>
							<Route
								exact
								path="/scams-recovery/bitcoin-scam-recovery"
								component={ScamsRecoveryMain}
							/>
							<Route
								exact
								path="/scams-recovery/online-banking-scam-recovery"
								component={ScamsRecoveryMain}
							/>
							<Route
								exact
								path="/scams-recovery/online-romance-scam-recovery"
								component={ScamsRecoveryMain}
							/>
							<Route
								exact
								path="/scams-recovery/investment-scam-recovery"
								component={ScamsRecoveryMain}
							/>
							<Route
								exact
								path="/scams-recovery/pig-butchering-scam-recovery"
								component={ScamsRecoveryMain}
							/>
							<Route
								exact
								path="/scams-recovery/forex-scam-recovery"
								component={ScamsRecoveryMain}
							/>

							<Route
								exact
								path="/scams-recovery/phishing-scam-recovery"
								component={ScamsRecoveryMain}
							/>
							<Route exact path="/trust-center" component={Trust} />
							{/* End Scams RECOVERY */}
							<Route exact path="/error" component={Error} />
							<Route component={Error} />
						</Switch>
					</ScrollToTop>
				</Router>
			</div>
		</>
	);
}

export default Routes;
