import React from "react";
import thumb from "../../assets/images/service-details-thumb.jpg";
import { Route, Link, Routes, useLocation } from "react-router-dom";
function DetailsAbout() {
	const location = useLocation();
	return (
		<>
			<section className="appie-service-details-area pt-100 ">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<div className="service-details-sidebar mr-50">
								<div className="service-download-widget">
									<Link to="/about-us">
										<i className="fal fa-file"></i>
										<span>About Us</span>
									</Link>
								</div>
								<div className="service-download-widget">
									<Link to="/terms">
										<i className="fal fa-file"></i>
										<span>{`Terms & Conditions`}</span>
									</Link>
								</div>
								<div className="service-download-widget">
									<Link to="/privacy">
										<i className="fal fa-file"></i>
										<span>Privacy Policy</span>
									</Link>
								</div>
								<div className="service-download-widget">
									<Link to="/legal">
										<i className="fal fa-file"></i>
										<span>Legal</span>
									</Link>
								</div>
								<div className="service-download-widget">
									<Link to="/trust-center">
										<i className="fal fa-file"></i>
										<span>Trust Center</span>
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-8">
							<div className="service-details-content">
								<div className="content">
									<h3 className="title">
										EXPERTS IN CRYPTOCURRENCY RECOVERY, FINANCIAL FRAUD
										SOLUTIONS, AND ASSET PROTECTION
									</h3>
									<p>
										Welcome to <b>Suave Reconn.</b> We are a team of experienced
										professionals who are dedicated to helping individuals,
										businesses, and organizations recover lost or stolen assets.
										Our mission is to provide our clients with exceptional
										service and results-driven solutions.
									</p>
									<p>
										At <b>Suave Reconn.</b> , we specialize in the recovery of
										lost and stolen <b>cryptocurrency</b>, funds, and assets due
										to <Link to="/scams/cryptocurrency-scam">crypto scams</Link>
										, <b>financial fraud</b>, and other misappropriation. With
										our advanced recovery techniques and a dedicated team of
										experts, we work tirelessly to help individuals and
										businesses regain access to their hard-earned wealth.
									</p>
									<p>
										Whether you’ve fallen victim to a{" "}
										<Link to="/scams/phishing-scam">phishing scam</Link>, lost
										access to your <b>crypto wallet</b>, or experienced
										significant financial fraud, we are here to provide swift,
										secure, and comprehensive solutions to recover your digital
										and physical assets.
									</p>

									{/* <span>
                                        He lost his bottle mufty spend a penny cheeky at public
                                        school daft get stuffed mate don't get shirty.
                                    </span> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default DetailsAbout;
